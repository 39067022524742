import { combineReducers } from "redux";

import companies from "./company-reducer";
import error from "./error-reducer";
import localization from "./localization-reducer";
import accounts from "./accounts-reducer";
import user from "./user-reducer";
import conversations from "./conversations-reducer";
import ledger from "./ledger-reducer";
import search from "./search-reducer";
import manage from "./manage-reducer";
import connector from "./connector-reducer";
import perspectives from "./perspectives-reducer";
import general from "./general-reducer";
import modal from "./modal-reducer";
import contextGroups from "./contextGroups-reducer";
import statements from "./statements-reducer";
import disputeCodes from "./disputeCodes-reducer";
import note from "./note-reducer";

const rootReducer = combineReducers({
  companies,
  error,
  localization,
  accounts,
  user,
  conversations,
  ledger,
  search,
  manage,
  connector,
  perspectives,
  general,
  modal,
  contextGroups,
  statements,
  disputeCodes,
  note,
});

export default rootReducer;
