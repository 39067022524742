import { actions } from "./general-actions";

const defaultInviteSettings = {
  showToast: false,
  toastInfo: {},
  toastTimeout: null,
  hasNetworkError: false,
  pageRowCount: 10,
  isAPILive: null
};

function generalReducer(state = { ...defaultInviteSettings }, action) {
  switch (action.type) {
    case actions.SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        toastTimeout: action.toastTimeout,
        toastInfo: action.toastInfo
      };
    case actions.HIDE_TOAST:
      return { ...state, showToast: false, toastTimeout: null };
    case actions.NETWORK_ERROR:
      return { ...state, hasNetworkError: true };
    case actions.CLEAR_NETWORK_ERROR:
      return { ...state, hasNetworkError: false };
    case actions.UPDATE_PAGE_ROW_COUNT:
      return { ...state, pageRowCount: action.pageRowCount };
    case actions.UPDATE_API_STATUS:
      return { ...state, isAPILive: action.isAPILive };
    default:
      return state;
  }
}

export default generalReducer;
