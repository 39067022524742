import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import Resources from "../lib/resources";

export const actions = {
  ...GeneralActions,
  ...{
    SET_LANGUAGE: "SET_LANGUAGE",

    GETTING_LANGUAGES: "GETTING_LANGUAGES",
    GOT_LANGUAGES: "GOT_LANGUAGES",
    FAILED_GET_LANGUAGES: "FAILED_GET_LANGUAGES",

    GETTING_LANGUAGE: "GETTING_LANGUAGE",
    GOT_LANGUAGE: "GOT_LANGUAGE",
    FAILED_GET_LANGUAGE: "FAILED_GET_LANGUAGE"
  }
};

const getAllLanguages = () => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_LANGUAGES });
  return data
    .get(`v1/api/internationalization`)
    .then(response => {
      dispatch({ type: actions.GOT_LANGUAGES, languages: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetLanguagesFailure, "internationalization");
      dispatch({ type: actions.FAILED_GET_LANGUAGES });
      throw error;
    });
};

export const dispatchToProps = dispatch => ({
  getAllLanguages: () => {
    dispatch(getAllLanguages());
  },
  setLanguage: languageId => {
    dispatch({ type: actions.SET_LANGUAGE, languageId });
  },
  setBrowserLanguage: () => {
    dispatch({ type: actions.SET_LANGUAGE, languageId: window.navigator.language });
  }
});
