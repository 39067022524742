import { actions } from "./connector-actions";

const defaultConnectorState = {
  allSupportedConnectors: [],
  allSupportedConnectorsIsFetched: false,
  isGettingAllSupportedConnectors: false,

  isFetchingConnectorConfiguration: false,
  fetchingConnectorConfiguration: [],
  fetchingConnectorConfigurationFailed: null,
  connectorConfigs: {},

  connectorVersionData: {},
  connectorVersionDataIsFetched: {},
  isGettingConnectorVersionData: {},

  noCompanySelected: true,

  puttingConnectorConfig: false,
  putConnectorConfig: false,
  failedPutConnectorConfig: false,
};

function connectorReducer(state = defaultConnectorState, action) {
  switch (action.type) {
    case actions.GETTING_ALL_SUPPORTED_CONNECTORS:
      return {
        ...state,
        isGettingAllSupportedConnectors: true,
        allSupportedConnectorsIsFetched: false
      };
    case actions.GOT_ALL_SUPPORTED_CONNECTORS:
      return {
        ...state,
        isGettingAllSupportedConnectors: false,
        allSupportedConnectorsIsFetched: true,
        allSupportedConnectors: action.allSupportedConnectors
      };
    case actions.GET_ALL_SUPPORTED_CONNECTORS_FAILED:
      return {
        ...state,
        isGettingAllSupportedConnectors: false,
        allSupportedConnectorsIsFetched: false
      };

    case actions.GETTING_CONNECTOR_VERSION_DATA:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: true
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: false
        }
      };
    case actions.GOT_CONNECTOR_VERSION_DATA:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: false
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: true
        },
        connectorVersionData: {
          ...state.connectorVersionData,
          [action.connectorId]: action.connectorVersionData
        }
      };
    case actions.GET_CONNECTOR_VERSION_DATA_FAILED:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: false
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: true
        }
      };

    case actions.FETCHING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        isFetchingConnectorConfiguration: true,
        fetchingConnectorConfiguration: [...state.fetchingConnectorConfiguration, action.connectorVersionId],
        fetchingConnectorConfigurationFailed: null
      };
    case actions.FETCHED_CONNECTOR_CONFIGURATION:
      let newFetchingState = [...state.fetchingConnectorConfiguration];
      newFetchingState.splice(state.fetchingConnectorConfiguration.indexOf(action.connectorVersionId));
      return {
        ...state,
        isFetchingConnectorConfiguration: false,
        fetchingConnectorConfigurationFailed: false,
        fetchingConnectorConfiguration: newFetchingState,
        connectorConfigs: {
          ...state.connectorConfigs,
          [action.connectorVersionId]: action.connectorConfig
        }
      };
    case actions.FETCH_CONNECTOR_CONFIGURATION_FAILED:
      let newFetchingState1 = [...state.fetchingConnectorConfiguration];
      newFetchingState1.splice(state.fetchingConnectorConfiguration.indexOf(action.connectorVersionId));
      return {
        ...state,
        isFetchingConnectorConfiguration: false,
        fetchingConnectorConfigurationFailed: true,
        fetchingConnectorConfiguration: newFetchingState1
      };

    case actions.PUTTING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: true,
        putConnectorConfig: false,
        failedPutConnectorConfig: false
      };
    case actions.PUT_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: false,
        putConnectorConfig: true,
        failedPutConnectorConfig: false
      };
    case actions.FAILED_PUT_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: false,
        putConnectorConfig: false,
        failedPutConnectorConfig: true
      };

    case actions.SELECT_COMPANY:
      // Prevent state from being reset when app loads
      if (state.noCompanySelected === true) {
        return { ...state, noCompanySelected: false };
      }
      return { ...defaultConnectorState, noCompanySelected: false };
    default:
      return state;
  }
}

export default connectorReducer;
