import { actions } from "./localization-actions";
import { isEmpty } from "../lib/utils";

const defaultLanguageSettings = {
  languageId: "en-US",
  resources: {},

  gettingLanguages: false,
  gotLanguages: false,
  failedGetLanguages: false
};

function localizationReducer(state = defaultLanguageSettings, action) {
  switch (action.type) {
    case  "FETCHED_ACCOUNTS":
      let flag = action.accounts[0].features?.enablePreferredLanguage;
      let languageId = action.accounts[0]?.preferredLanguageId || defaultLanguageSettings.languageId;
      if(flag) return({...state,...{languageId}}); 
    case actions.SET_LANGUAGE:
      window.strings = generateStringsMap(state.resources[action.languageId]);
      return { ...state, ...{ languageId: action.languageId } };

    case actions.GETTING_LANGUAGES:
      return { ...state, gettingLanguages: true, gotLanguages: false, failedGetLanguages: false };
    case actions.GOT_LANGUAGES:
      if (!isEmpty(action.languages[state.languageId])) {
        window.strings = generateStringsMap(action.languages[state.languageId]);
      }
      return {
        ...state,
        gettingLanguages: false,
        gotLanguages: true,
        failedGetLanguages: false,
        resources: { ...state.resources, ...action.languages }
      };
    case actions.FAILED_GET_LANGUAGES:
      return { ...state, gettingLanguages: false, gotLanguages: false, failedGetLanguages: true };

    default:
      return state;
  }
}

function generateStringsMap(language) {
  if (isEmpty(language)) {
    return {};
  }
  let stringsMap = {};
  language?.value?.forEach(string => (stringsMap[string.name.toLocaleUpperCase()] = string.value));
  return stringsMap;
}

export default localizationReducer;
