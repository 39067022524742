import { actions } from "./disputeCodes-actions";

const defaultDisputeCodeSettings = {
  disputeCodes: [],
  gettingDisputeCode: false,
  gotDisputeCode: false,
  failedGetDisputeCode: false
};

function disputeCodeReducer(state = defaultDisputeCodeSettings, action) {
  switch (action.type) {
    case actions.GETTING_DISPUTE_CODE:
      return { ...state, gettingDisputeCode: true, gotDisputeCode: false, failedGetDisputeCode: false };
    case actions.GOT_DISPUTE_CODE:
      return {
        ...state,
        gettingDisputeCode: false,
        gotDisputeCode: true,
        failedGetDisputeCode: false,
        disputeCodes: action.disputeCodes
      };
    case actions.FAILED_GET_DISPUTE_CODE:
      return { ...state, gettingDisputeCode: false, gotDisputeCode: false, failedGetDisputeCode: true };
    default:
      return state;
  }
}

export default disputeCodeReducer;
