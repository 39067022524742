import data from "../lib/data";
import _ from "lodash";
import { debounce, isEmpty } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_SEARCH_RESULTS: "FETCHING_SEARCH_RESULTS",
    FETCHED_SEARCH_RESULTS: "FETCHED_SEARCH_RESULTS",
    FETCH_SEARCH_RESULTS_FAILED: "FETCH_SEARCH_RESULTS_FAILED",

    CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS"
  }
};

const debouncedSearchResultsGet = debounce((dispatch, companyId, request) => {
  let endpoint = `v1/api/search/${companyId}/conversations`;
  if (isEmpty(request.request)) {
    return dispatch({ type: actions.CLEAR_SEARCH_RESULTS });
  }

  data
    .post(endpoint, request)
    .then(response => {
      dispatch({
        type: actions.FETCHED_SEARCH_RESULTS,
        searchResults: response.data.value,
        request
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_SEARCH_RESULTS_FAILED });
      handlePromiseError(rejection, "TODO: Getting search results failed. Please try again.", "search results");
    });
}, 400);

const debouncedFetchSearchResults = (companyId, request) => (dispatch, getState) => {
  const store = getState().search;
  if (store.isFetchingSearchResults && _.isEqual(request, store.searchQuery)) {
    return;
  }

  dispatch({ type: actions.FETCHING_SEARCH_RESULTS, request });
  debouncedSearchResultsGet(dispatch, companyId, request);
};

export const dispatchToProps = dispatch => ({
  debouncedFetchSearchResults: (companyId, request) => {
    dispatch(debouncedFetchSearchResults(companyId, request));
  },
  clearSearchResults: () => {
    dispatch({ type: actions.CLEAR_SEARCH_RESULTS });
  }
});
