import { actions as GeneralActions } from "./general-actions";
import data from "../lib/data";
import {saveAs} from 'file-saver';
export const actions = {
    ...GeneralActions,
    ...{  
      CREATING_NOTE: "CREATING_NOTE",
      CREATED_NOTE: "CREATED_NOTE",
      CREATE_NOTE_FAILED: "CREATE_NOTE_FAILED",

      GETTING_PORTAL_ATTACHMENT: "GETTING_PORTAL_ATTACHMENT",
      GOT_PORTAL_ATTACHMENT: "GOT_PORTAL_ATTACHMENT",
      GET_PORTAL_ATTACHMENT_FAILED: "GET_PORTAL_ATTACHMENT_FAILED",

      DOWNLOADED_ATTACHMENT: "DOWNLOADED_ATTACHMENT",
      DOWNLOADING_ATTACHMENT: "DOWNLOADING_ATTACHMENT",
      DOWNLOAD_ATTACHMENT_FAILED: "DOWNLOAD_ATTACHMENT_FAILED"

    }
  };

  export const createNote = (companyId,customerKey,content) => (dispatch, getState) => {
    dispatch({ type: actions.CREATING_NOTE});
    const endpoint = `v4/api/accountoverview/for/${companyId}/sendportalmessage`;
    const headers = {'Content-Type': 'multipart/form-data'};
    const formData = new FormData();
    formData.append('StorageType', content.StorageType);
    formData.append('UserType', content.UserType);
    formData.append('BlobPath', content.BlobPath);
    formData.append('IsAccountNote', content.IsAccountNote);
    formData.append('IsAttachment', true);
    formData.append('TaskDescription', content.TaskDescription);
    formData.append('Note', content.Note);
    formData.append('CustomerKey',customerKey);
    formData.append('ActivityType', content.ActivityType);
    formData.append('TaskKey', content.TaskKey);
    content.Files.forEach(file => {
      formData.append('Files', file);
    });
    return data.post
    (endpoint,formData,headers)
      .then(res => {
        dispatch({ type: actions.CREATED_NOTE });
        return res;
      })
      .catch(err => {
        dispatch({ type: actions.CREATE_NOTE_FAILED });
        throw err;
      });
  };

  export const  getPortalAttachment = (entitykey) => (dispatch, getState) => {
    dispatch({ type: actions.GETTING_PORTAL_ATTACHMENT });
    const endpoint = `v4/api/accountoverview/portalattachment/for/${entitykey}`;
    return data.get(endpoint)
      .then(res => {
        dispatch({ type: actions.GOT_PORTAL_ATTACHMENT,portalAttachment:res.data});
      })
      .catch(err => {
        dispatch({ type: actions.GET_PORTAL_ATTACHMENT_FAILED });
        throw err;
      });
  }; 

  export const downloadAttachment = (groupKey,attachmentInfo) => (dispatch, getState) => {
    dispatch({ type: actions.DOWNLOADING_ATTACHMENT });
    const endpoint = `v4/api/emailviewer/for/${groupKey}/downloademailattachment/`;
    const headers = {'Content-Type': 'blob', responseType: 'arraybuffer'};
    return data.post(endpoint,attachmentInfo,headers).then(res => {
      saveBlobAsDownload(res.data, attachmentInfo.fileName);
  })

 function saveBlobAsDownload(blob, fileName) {
    saveAs(new Blob([blob]), fileName);
  }
};

  export const dispatchToProps = dispatch => ({
    createNote: (companyId,customerKey,userId,content) => {
      return dispatch(createNote(companyId,customerKey,userId,content));
    },

    getPortalAttachment: (entitykey) => {
      return dispatch(getPortalAttachment(entitykey));
    } ,

    downloadAttachment: (groupKey,attachmentInfo) => {
      return dispatch(downloadAttachment(groupKey,attachmentInfo));
    } 

  });