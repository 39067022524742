import { actions } from "./error-actions";
import { find, findIndex } from "../lib/utils";

const defaultErrorState = {
  errors: [], // { severity: "error", message: "error message", type: "error-type", field: "fieldname" }
  hasError: false,
  hasFieldError: false
};

function errorReducer(state = { ...defaultErrorState }, action) {
  let newErrorArray;
  switch (action.type) {
    case actions.RAISE_ERROR:
      newErrorArray = state.errors.concat(
        [action.error].filter(error => findIndex(state.errors, e => JSON.stringify(e) === JSON.stringify(error)) < 0)
      ); // de-duplication of errors.
      return {
        ...state,
        errors: newErrorArray,
        hasError: newErrorArray.length > 0,
        hasFieldError: find(newErrorArray, err => {
          return !!err.field;
        })
      };
    case actions.CLEAR_ERROR:
      newErrorArray = state.errors.filter(
        error => error.type !== action.errorType || (!!action.field && error.field !== action.field)
      );
      return {
        ...state,
        errors: newErrorArray,
        hasError: newErrorArray.length > 0,
        hasFieldError: find(newErrorArray, err => {
          return !!err.field;
        })
      };
    case actions.CLEAR_ERRORS:
      return { ...state, errors: [], hasError: false, hasFieldError: false };

    case actions.CLEAR_DATA:
      return { ...defaultErrorState };

    default:
      return state;
  }
}

export default errorReducer;
