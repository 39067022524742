import { handlePromiseError } from "./error-actions";
import auth from "../lib/auth";
import { updateConfig } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import data from "../lib/data";

export const actions = {
  ...GeneralActions,
  ...{
    LOGGED_IN: "LOGGED_IN",
    LOGGING_IN: "LOGGING_IN",
    LOGIN_FAILED: "LOGIN_FAILED",
    TOKEN_EXPIRING: "TOKEN_EXPIRING",

    LOGGED_OUT: "LOGGED_OUT",
    LOGGING_OUT: "LOGGING_OUT",
    LOGOUT_FAILED: "LOGOUT_FAILED",

    GETTING_USER_CONFIG: "GETTING_USER_CONFIG",
    GOT_USER_CONFIG: "GOT_USER_CONFIG",
    GET_USER_CONFIG_FAILED: "GET_USER_CONFIG_FAILED",
    GET_USER_ZENDESK_CONFIG: "GET_USER_ZENDESK_CONFIG",
    GOT_USER_ZENDESK_CONFIG: "GOT_USER_ZENDESK_CONFIG",
    GOT_USER_ZENDESK_CONFIG_FAILED: "GOT_USER_ZENDESK_CONFIG_FAILED",
  }
};

const login = (userName, password, refresh) => (dispatch, getState) => {
  let user = getState().user;
  if (refresh !== true && (user.isLoggedIn || user.isLoggingIn)) return;
  dispatch({ type: actions.LOGGING_IN, didLoginFail: false });
  return auth
    .login(userName, password)
    .then(response => {
      dispatch(loggedIn(response.identityToken));
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.LOGIN_FAILED, didLoginFail: true });
      handlePromiseError(response, "TODO: Logging in failed.", "authorization", () => {
        return;
      });
    });
};

export const loggedIn = token => (dispatch, getState) => {
  window.localStorage.setItem("logged_in", "true");
  window.localStorage.setItem("id_token", token);
  updateConfig({ AccessToken: token });
  return dispatch({ type: actions.LOGGED_IN, didLoginFail: false, token: token });
};

const samlLogin = (provider, saml) => (dispatch, getState) => {
  let user = getState().user;
  if (user.isLoggedIn || user.isLoggingIn) return;
  dispatch({ type: actions.LOGGING_IN, samlLogin: true });
  auth
    .samlLogin(provider, saml)
    .then(token => {
      dispatch(loggedIn(token));
    })
    .catch(err => {
      return;
    });
};

export const logout = () => (dispatch, getState) => {
  let user = getState().user;
  if (user.isLoggedOut || user.isLoggingOut) return;

  window.localStorage.removeItem("saml");
  window.localStorage.removeItem("saml-provider");
  window.localStorage.removeItem("cust-page");
  window.localStorage.removeItem("id_token");
  window.localStorage.removeItem("logged_in");
  updateConfig({ AccessToken: undefined });
  dispatch({ type: actions.CLEAR_DATA });

  dispatch({ type: actions.LOGGING_OUT });
  auth
    .logout()
    .then(response => {
      dispatch({ type: actions.LOGGED_OUT });
      window.location = "/#/login";
    })
    .catch(error => {
      dispatch({ type: actions.LOGOUT_FAILED });
      handlePromiseError(error, "TODO: Logging out failed", "logout");
    });
};

export const getMyUserIdFromToken = () => (dispatch, getState) => {
  return (getState().user.decoded || {}).sub;
};

export const getMyDisplayNameFromToken = () => (dispatch, getState) => {
  let decoded = getState().user.decoded || {};
  return decoded.name || decoded.given_name;
};

export const getMyUserNameFromToken = () => (dispatch, getState) => {
  return (getState().user.decoded || {}).preferred_username;
};

export const getUserConfig = () => (dispatch, getState) => {
  let user = getState().user;
  if (user.gettingUserConfig) return;
  dispatch({ type: actions.GETTING_USER_CONFIG });
  data
    .get("v1/api/user/settings")
    .then(response => {
      dispatch({ type: actions.GOT_USER_CONFIG, config: response.data });
    })
    .catch(error => {
      dispatch({ type: actions.GET_USER_CONFIG_FAILED });
      handlePromiseError(error, "TODO: Getting user settings failed.", "user settings");
    });
};
export const getZenDeskConfig = () => (dispatch) => {
  dispatch({ type: actions.GET_USER_ZENDESK_CONFIG });
  data
    .post("v1/api/user/Zendesk/info", {clientType:0})
    .then(response => {
      dispatch({ type: actions.GOT_USER_ZENDESK_CONFIG, config: response.data });
    })
    .catch(error => {
      dispatch({ type: actions.GOT_USER_ZENDESK_CONFIG_FAILED });
    });

}

export const dispatchToProps = dispatch => ({
  login: (userName, password) => {
    return dispatch(login(userName, password));
  },
  tokenExpiring: () => {
    dispatch({ type: actions.TOKEN_EXPIRING });
  },
  samlLogin: (provider, saml) => {
    dispatch(samlLogin(provider, saml));
  },
  logout: () => {
    dispatch(logout());
  },
  loggedIn: token => {
    dispatch(loggedIn(token));
  },
  getMyUserIdFromToken: () => {
    return dispatch(getMyUserIdFromToken());
  },
  getMyDisplayNameFromToken: () => {
    return dispatch(getMyDisplayNameFromToken());
  },
  getMyUserNameFromToken: () => {
    return dispatch(getMyUserNameFromToken());
  },
  getUserConfig: () => {
    dispatch(getUserConfig());
  },
  getZenDeskConfig: () => {
    dispatch(getZenDeskConfig());
  }
});
