import React, { Component } from "react";

export const isDebug = false;

// See also debugPage.jsx

export default class EasterEggPage extends Component {
    // PRODUCTION CODE
    // MAKE NO CHANGE
    render() {
      return (
        <React.Fragment>
          <div>Hello from the Lockstep Development Team!</div>
        </React.Fragment>
      );
    }
  }