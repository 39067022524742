import { actions } from "./note-actions";

export const defaultCreatingNoteState = {
    creatingNote: false,
    createdNote: false,
    createdNoteFailed: false,

    portalAttachment: [],
    gettingPortalAttachment: false,
    getPortalAttachmentFailed: false,
    gotPortalAttachment :false,

    downloadingAttachment: false,
    downloadAttachmentFailed: false,
    downloadedAttachment :false


}


function NoteReducer(state = defaultCreatingNoteState, action) {
    switch (action.type) {    
        case actions.CREATING_NOTE:
            return { ...state, creatingNote: true, createdNote: false, createdNoteFailed: false };
          case actions.CREATED_NOTE:
            return { ...state, creatingNote: false, createdNote: true,createdNoteFailed: false };
          case actions.CREATE_NOTE_FAILED:
            return { ...state, creatingNote: false,createdNote: false, createdNoteFailed: true };
            
            case actions.GETTING_PORTAL_ATTACHMENT:
              return {
                ...state,
                gettingPortalAttachment: true,
                portalAttachment: [],
                getPortalAttachmentFailed: false,
                gotPortalAttachment :false                
              };
            case actions.GOT_PORTAL_ATTACHMENT:
              return {
                ...state,
                gettingPortalAttachment: false,
                gotPortalAttachment: true,
                getPortalAttachmentFailed: false,
                portalAttachment: action.portalAttachment
              };
            case actions.GET_PORTAL_ATTACHMENT_FAILED:
              return {
                ...state,
                getPortalAttachmentFailed: true,
                portalAttachment: [],
                gotPortalAttachment :false,
                gettingPortalAttachment: false
              };

              case actions.DOWNLOADING_ATTACHMENT:
                return {
                  ...state,
                  downloadingAttachment: true,
                  downloadAttachmentFailed: false,
                  downloadedAttachment :false                
                };
              case actions.DOWNLOADED_ATTACHMENT:
                return {
                  ...state,
                  downloadingAttachment: false,
                  downloadedAttachment: true,
                  downloadAttachmentFailed: false
                };
              case actions.DOWNLOAD_ATTACHMENT_FAILED:
                return {
                  ...state,
                  downloadAttachmentFailed: true,
                  downloadingAttachment: false,
                  downloadedAttachment :false
                };
                                   
            default:
                return state;   
    }
}

export default NoteReducer;