import { actions } from "./statements-actions";
import { includes, updateSelectedRows } from "../lib/utils";
import resources from "../lib/resources";

export const defaultStatementsState = {
  fetchingAllInvoices: false,
  fetchedAllInvoices: false,
  fetchAllInvoicesFailed: false,

  closedInvoices: [],
  closedInvoicesCount: null,
  closedInvoicesSkip: 0,
  closedTotalAmount: null,
  closedTotalAmountCurrentYear: null,
  closedTotalAmountPastYear: null,
  closedFromDate: null,
  closedToDate: null,
  closedFromDueDate: null,
  closedToDueDate: null,
  closedInvoicesNextLink: null,
  closedInvoicesCurrency: null,
  closedInvoicesCurrencyStats: {},
  isFetchingClosedInvoices: false,
  fetchingClosedInvoicesFailed: null,
  closedInvoicesSortBy: "IssueDate",
  closedInvoicesSortDirection: "desc",

  isFetchingClosedInvoicesTemplate: false,
  fetchedClosedInvoicesTemplate: false,
  fetchingClosedInvoicesTemplateFailed: false,

  openInvoices: [],
  openInvoicesCount: null,
  openInvoicesSkip: 0,
  openTotalAmount: null,
  openPastDueCount: null,
  openFromDate: null,
  openToDate: null,
  openFromDueDate: null,
  openToDueDate: null,
  openInvoicesNextLink: null,
  openInvoicesCurrency: null,
  openInvoicesCurrencyStats: {},
  isFetchingOpenInvoices: false,
  fetchingOpenInvoicesFailed: null,
  openInvoicesSortBy: "IssueDate",
  openInvoicesSortDirection: "asc",

  isFetchingOpenInvoicesTemplate: false,
  fetchedOpenInvoicesTemplate: false,
  fetchingOpenInvoicesTemplateFailed: false,

  payments: [],
  paymentsCount: null,
  paymentsSkip: 0,
  paymentTotalAmount: null,
  paymentsTotalAmountCurrentYear: null,
  paymentsTotalAmountPastYear: null,
  paymentsTotalUnapplied: null,
  paymentsTotalUnappliedOrPending: null,
  paymentFromDate: null,
  paymentToDate: null,
  paymentsNextLink: null,
  paymentsCurrency: null,
  paymentsCurrencyStats: {},
  isFetchingPayments: false,
  fetchingPaymentsFailed: null,
  paymentsSortBy: "ProcessedDate",
  paymentsSortDirection: "desc",

  unappliedOrPendingPayments: [],
  unappliedOrPendingPaymentsCount: null,
  unappliedOrPendingPaymentsSkip: 0,
  isFetchingUnappliedOrPendingPayments: false,
  fetchingUnappliedOrPendingPaymentsFailed: false,
  unappliedPaymentsSortBy: "processedDate",
  unappliedPaymentsSortDirection: "desc",

  searchTerm: "",
  searchResults: [],
  isShowingSearchResult: false,
  fetchingStatementsSearchResults: false,
  fetchedStatementsSearchResults: false,
  failedFetchStatementsSearchResults: false,

  isFetchingPaymentInfo: false,
  fetchedPaymentInfo: false,
  fetchingPaymentInfoFailed: false,
  paymentInfo: {},

  postingAutoPayConfig: false,
  postedAutoPayConfig: false,
  failedPostAutoPayConfig: false,

  isMakingPayment: false,
  makingPaymentFailed: false,

  isAddingPaymentMethod: false,  
  addingPaymentMethodFailed: false,

  fetchingExportTable: false,
  fetchedExportTable: false,
  failedFetchExportTable: false,

  fetchingCurrencies: false,
  fetchedCurrencies: false,
  failedFetchCurrencies: false,
  selectedCurrency: "USD",
  currencies: [],

  fetchingCurrencyStats: false,
  fetchedCurrencyStats: false,
  failedFetchCurrencyStats: false,

  companyId: null,
  withCompanyId: null,
  selectedKeys: [],
  selectedRows: [],
  disallowPortalSendMessage: false
};

function statementsReducer(state = defaultStatementsState, action) {
  if (window.logToConsole) console.log(action);

  switch (action.type) {
    case actions.CLEAR_STATEMENTS_SEARCH_RESULTS:
      return {
        ...state,
        searchTerm: "",
        searchResults: [],
        fetchedStatementsSearchResults: false,
        fetchingStatementsSearchResults: false,
        failedFetchStatementsSearchResults: false
      };
    case actions.SET_OPEN_INVOICES_TO_SEARCH_RESULT:
      return { ...state, openInvoices: action.openInvoices, isShowingSearchResult: true };
    case actions.SET_CLOSED_INVOICES_TO_SEARCH_RESULT:
      return { ...state, closedInvoices: action.closedInvoices, isShowingSearchResult: true };
    case actions.SET_PAYMENTS_TO_SEARCH_RESULT:
      return { ...state, payments: action.payments, isShowingSearchResult: true };
    case actions.FETCHING_STATEMENTS_SEARCH_RESULTS:
      return {
        ...state,
        searchTerm: action.searchTerm,
        fetchingStatementsSearchResults: true,
        fetchedStatementsSearchResults: false,
        failedFetchSearchResults: false
      };
    case actions.FETCHED_STATEMENTS_SEARCH_RESULTS:
      if (state.searchTerm !== action.searchTerm) {
        return { ...state };
      }
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchResults: action.searchResults,
        fetchingStatementsSearchResults: false,
        fetchedStatementsSearchResults: true,
        failedFetchSearchResults: false
      };
    case actions.FETCH_STATEMENTS_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        fetchingStatementsSearchResults: false,
        fetchedStatementsSearchResults: false,
        failedFetchSearchResults: true
      };
    case actions.FETCHING_ALL_INVOICES:
      return {
        ...state,
        fetchingAllInvoices: true,
        fetchedAllInvoices: false,
        fetchAllInvoicesFailed: false
      };
    case actions.FETCHED_ALL_INVOICES:
      return {
        ...state,
        fetchingAllInvoices: false,
        fetchedAllInvoices: true,
        fetchAllInvoicesFailed: false
      };
    case actions.FETCH_ALL_INVOICES_FAILED:
      return {
        ...state,
        fetchingAllInvoices: false,
        fetchedAllInvoices: false,
        fetchAllInvoicesFailed: true
      };

    case actions.FETCHING_CLOSED_INVOICES:
      const nextClosedState = {
        ...state,
        isFetchingClosedInvoices: true,
        withCompanyId: action.withCompanyId,
        isShowingSearchResult: false
      };
      if (action.isNextLink !== true) {
        nextClosedState.closedInvoices = [];
      }
      return nextClosedState;
    case actions.FETCHED_CLOSED_INVOICES:
      if (action.withCompanyId !== state.withCompanyId) {
        return state;
      }

      return {
        ...state,
        closedInvoices: [...state.closedInvoices, ...action.closedInvoices],
        closedInvoicesCount: action.count,
        closedInvoicesSkip: action.skip,
        closedTotalAmount: action.totalAmount,
        closedTotalAmountCurrentYear: action.totalAmountCurrentYear,
        closedTotalAmountPastYear: action.totalAmountPastYear,
        closedInvoicesNextLink: action.nextLink,
        closedInvoicesCurrency: action.currency || state.closedInvoicesCurrency,
        closedInvoicesCurrencyStats: {
          ...state.closedInvoicesCurrencyStats,
          [action.currency || state.closedInvoicesCurrency]: action.response
        },
        isFetchingClosedInvoices: false,
        fetchingClosedInvoicesFailed: false,
        disallowPortalSendMessage: action.disallowPortalSendMessage
      };
    case actions.FETCH_CLOSED_INVOICES_FAILED:
      return {
        ...state,
        isFetchingClosedInvoices: false,
        fetchingClosedInvoicesFailed: true
      };

    case actions.FETCHING_CLOSED_INVOICES_TEMPLATE:
      return { ...state, isFetchingClosedInvoicesTemplate: true };
    case actions.FETCHED_CLOSED_INVOICES_TEMPLATE:
      return {
        ...state,
        isFetchingClosedInvoicesTemplate: false,
        fetchingClosedInvoicesTemplateFailed: false,
        fetchedClosedInvoicesTemplate: true
      };
    case actions.FETCH_CLOSED_INVOICES_TEMPLATE_FAILED:
      return {
        ...state,
        isFetchingClosedInvoicesTemplate: false,
        fetchingClosedInvoicesTemplateFailed: true,
        fetchedClosedInvoicesTemplate: false
      };
    case actions.UPDATE_CLOSED_INVOICES_SORT:
      return {
        ...state,
        closedInvoicesSortBy: action.sortBy,
        closedInvoicesSortDirection: action.sortDirection
      };
    case actions.UPDATE_CLOSED_INVOICES_DATE:
      return {
        ...state,
        closedFromDate: action.fromDate,
        closedToDate: action.toDate,
        closedFromDueDate: action.dueByFromDate,
        closedToDueDate: action.dueByToDate,
        closedInvoicesSkip:0
      };
    case actions.SET_SELECTED_KEYS:
      const modifyKeys = action.keys;
      const modifyRows = action.keys === "all" ? "all" : action.rows;
      const newSelectedKeys = updateSelectedRows(modifyKeys, state.selectedKeys);
      const newSelectedRows = updateSelectedRows(modifyRows, state.selectedRows);
      return {
        ...state,
        selectedKeys: newSelectedKeys,
        selectedRows: newSelectedRows
      };
    case actions.FETCHING_OPEN_INVOICES:
      const nextOpenState = {
        ...state,
        isShowingSearchResult: false,
        isFetchingOpenInvoices: true,
        withCompanyId: action.withCompanyId
      };
      if (action.isNextLink !== true) {
        nextOpenState.openInvoices = [];
      }
      return nextOpenState;
    case actions.FETCHED_OPEN_INVOICES:

      if (action.withCompanyId !== state.withCompanyId) {
        return state;
      }
      let selectedKeys = state.selectedKeys;
      let selectedRows = action.selectAll ? action.response.value : state.selectedRows;
      if (action.selectAll) {
        selectedKeys = [];
        for (const item in action.response.value) {
          selectedKeys.push(action.response.value[item].ledgerHash);
        }
      }

      return {
        ...state,
        openInvoices: [...state.openInvoices, ...action.openInvoices],
        openInvoicesCount: action.count,
        openInvoicesSkip: action.skip,
        openTotalAmount: action.totalBalance,
        openPastDueCount: action.pastDueCount,
        openTotalPastDue:action.totalPastDue,
        openInvoicesNextLink: action.nextLink,
        openInvoicesCurrency: action.currency || state.openInvoicesCurrency,
        openInvoicesCurrencyStats: {
          ...state.openInvoicesCurrencyStats,
          [action.currency]: action.response
        },
        isFetchingOpenInvoices: false,
        fetchingOpenInvoicesFailed: false,
        selectedKeys,
        selectedRows,
        disallowPortalSendMessage: action.disallowPortalSendMessage
      };
    case actions.FETCH_OPEN_INVOICES_FAILED:
      return {
        ...state,
        isFetchingOpenInvoices: false,
        fetchingOpenInvoicesFailed: true
      };

    case actions.FETCHING_OPEN_INVOICES_TEMPLATE:
      return { ...state, isFetchingOpenInvoicesTemplate: true };
    case actions.FETCHED_OPEN_INVOICES_TEMPLATE:
      return {
        ...state,
        isFetchingOpenInvoicesTemplate: false,
        fetchingOpenInvoicesTemplateFailed: false,
        fetchedOpenInvoicesTemplate: true
      };
    case actions.FETCH_OPEN_INVOICES_TEMPLATE_FAILED:
      return {
        ...state,
        isFetchingOpenInvoicesTemplate: false,
        fetchingOpenInvoicesTemplateFailed: true,
        fetchedOpenInvoicesTemplate: false
      };
    case actions.UPDATE_OPEN_INVOICES_SORT:
      return {
        ...state,
        openInvoicesSortBy: action.sortBy,
        openInvoicesSortDirection: action.sortDirection
      };
    case actions.UPDATE_OPEN_INVOICES_DATE:
      return {
        ...state,
        openFromDate: action.fromDate,
        openToDate: action.toDate,
        openFromDueDate: action.dueByFromDate,
        openToDueDate: action.dueByToDate,
        openInvoicesSkip: 0,
      };

    case actions.FETCHING_PAYMENTS: {
      const nextPaymentsState = {
        ...state,
        isFetchingPayments: true,
        withCompanyId: action.withCompanyId,
        isShowingSearchResult: false
      };
      if (action.isNextLink !== true) {
        nextPaymentsState.payments = [];
      }
      return nextPaymentsState;
    }
    case actions.FETCHED_PAYMENTS:
      if (action.withCompanyId !== state.withCompanyId) {
        return state;
      }
      return {
        ...state,
        payments: [...state.payments, ...action.payments],
        paymentsCount: action.count,
        paymentsSkip: action.skip,
        paymentsTotalAmount: action.totalAmount,
        paymentsTotalAmountCurrentYear: action.totalAmountCurrentYear,
        paymentsTotalAmountPastYear: action.totalAmountPastYear,
        paymentsTotalUnapplied: action.totalBalance,
        paymentsNextLink: action.nextLink,
        paymentsCurrency: action.currency || state.paymentsCurrency,
        paymentsCurrencyStats: {
          ...state.paymentsCurrencyStats,
          [action.currency || state.paymentsCurrency]: action.response
        },
        isFetchingPayments: false,
        fetchingPaymentsFailed: false
      };
    case actions.FETCH_PAYMENTS_FAILED:
      return {
        ...state,
        isFetchingPayments: false,
        fetchingPaymentsFailed: true
      };

    case actions.FETCHING_UNAPPLIED_PAYMENTS: {
      const nextPaymentsState = {
        ...state,
        isFetchingUnappliedOrPendingPayments: true,
        withCompanyId: action.withCompanyId,
        isShowingSearchResult: false
      };

      if (action.skip === 0) {
        nextPaymentsState.unappliedOrPendingPayments = [];
      }
      return nextPaymentsState;
    }
    case actions.FETCHED_UNAPPLIED_PAYMENTS:
      if (action.withCompanyId !== state.withCompanyId) {
        return state;
      }
      return {
        ...state,
        unappliedOrPendingPayments: [...action.unappliedOrPendingPayments],
        unappliedOrPendingPaymentsCount: action.count,
        unappliedOrPendingPaymentsSkip: action.skip,
        paymentsTotalUnapplied: action.totalBalance,
        paymentsTotalUnappliedOrPending: action.totalAmount,
        isFetchingUnappliedOrPendingPayments: false,
        fetchingUnappliedOrPendingPaymentsFailed: false
      };
    case actions.FETCH_UNAPPLIED_PAYMENTS_FAILED:
      return {
        ...state,
        isFetchingUnappliedOrPendingPayments: false,
        fetchingUnappliedOrPendingPaymentsFailed: true
      };

    case actions.POSTING_AUTO_PAY_CONFIG:
      return {
        ...state,
        postingAutoPayConfig: true,
        postedAutoPayConfig: false,
        failedPostAutoPayConfig: false
      };
    case actions.POSTED_AUTO_PAY_CONFIG:
      return {
        ...state,
        postingAutoPayConfig: false,
        postedAutoPayConfig: true,
        failedPostAutoPayConfig: false
      };
    case actions.FAILED_POST_AUTO_PAY_CONFIG:
      return {
        ...state,
        postingAutoPayConfig: false,
        postedAutoPayConfig: false,
        failedPostAutoPayConfig: true
      };

    case actions.MAKING_PAYMENT:
      return {
        ...state,
        isMakingPayment: true
      };
    case actions.MADE_PAYMENT:
      return {
        ...state,
        isMakingPayment: false,
        makingPaymentFailed: false
      };
    case actions.MAKE_PAYMENT_FAILED:
      return {
        ...state,
        isMakingPayment: false,
        makingPaymentFailed: true
      };

    case actions.ADDING_PAYMENT_METHOD:
      const nextState = {
        ...state,
        isAddingPaymentMethod: true
      };

      if (action.setAsDefault === true) {
        const withCompanyPaymentMethods = ((state.paymentInfo[action.withCompanyId] || {}).paymentMethods || []).map(
          pMethod => {
            if (pMethod.paymentMethodId === action.paymentMethodId) {
              return { ...pMethod, isDefault: true };
            } else if (pMethod.isDefault === true) {
              return { ...pMethod, isDefault: false };
            }
            return pMethod;
          }
        );

        nextState.paymentInfo = {
          ...state.paymentInfo,
          [action.withCompanyId]: {
            ...state.paymentInfo[action.withCompanyId],
            paymentMethods: withCompanyPaymentMethods
          }
        };
      }
      return nextState;
    case actions.ADDED_PAYMENT_METHOD:
      return {
        ...state,
        isAddingPaymentMethod: false,
        addingPaymentMethodFailed: false       
      };
    case actions.ADD_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        isAddingPaymentMethod: false,
        addingPaymentMethodFailed: true
      };

    case actions.DELETING_PAYMENT_METHOD:
      return {
        ...state,
        isDeletingPaymentMethod: true
      };
    case actions.DELETED_PAYMENT_METHOD:
      if (action?.message?.toLocaleLowerCase() === resources.PaymentMethodDeletedSuccessfully.toLocaleLowerCase()) {
        const withCompanyPaymentMethods = ((state.paymentInfo[action.withCompanyId] || {}).paymentMethods || []).filter(
          pMethod => pMethod.paymentMethodId !== action.paymentMethodId
        );
        return {
          ...state,
          isDeletingPaymentMethod: false,
          deletingPaymentMethodFailed: false,
          paymentInfo: {
            ...state.paymentInfo,
            [action.withCompanyId]: {
              ...state.paymentInfo[action.withCompanyId],
              paymentMethods: withCompanyPaymentMethods
            }
          }
        };
      }
      return {
        ...state,
        isDeletingPaymentMethod: false,
        deletingPaymentMethodFailed: false
      }
    case actions.DELETE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        isDeletingPaymentMethod: false,
        deletingPaymentMethodFailed: true
      };

    case actions.FETCHING_PAYMENT_INFO:
      return { ...state, isFetchingPaymentInfo: true, fetchedPaymentInfo: false,
         fetchingPaymentInfoFailed: false
      };
    case actions.FETCHED_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: { ...state.paymentInfo, [action.withCompanyId]: action.paymentInfo },
        isFetchingPaymentInfo: false,
        fetchedPaymentInfo: true,
        fetchingPaymentInfoFailed: false
      };
    case actions.FETCH_PAYMENT_INFO_FAILED:
      return {
        ...state,
        isFetchingPaymentInfo: false,
        fetchedPaymentInfo: false,
        fetchingPaymentInfoFailed: true
      };

    case actions.UPDATE_PAYMENTS_SORT:
      return {
        ...state,
        paymentsSortBy: action.sortBy,
        paymentsSortDirection: action.sortDirection
      };
    case actions.UPDATE_UNAPPLIED_PAYMENTS_SORT:
      return {
        ...state,
        unappliedPaymentsSortBy: action.sortBy,
        unappliedPaymentsSortDirection: action.sortDirection
      };

    case actions.UPDATE_PAYMENTS_DATE:
      return {
        ...state,
        paymentsFromDate: action.fromDate,
        paymentsToDate: action.toDate,
        paymentsSkip: 0
      };

    case actions.FETCHING_TABLE_EXPORT:
      return {
        ...state,
        fetchingExportTable: true,
        fetchedExportTable: false,
        failedFetchExportTable: false
      };
    case actions.FETCHED_TABLE_EXPORT:
      return {
        ...state,
        fetchingExportTable: false,
        fetchedExportTable: true,
        failedFetchExportTable: false
      };
    case actions.FETCH_TABLE_EXPORT_FAILED:
      return {
        ...state,
        fetchingExportTable: false,
        fetchedExportTable: false,
        failedFetchExportTable: true
      };

    case actions.FETCHING_CURRENCIES:
      return {
        ...state,
        fetchingCurrencies: true,
        fetchedCurrencies: false,
        failedFetchCurrencies: false
      };
    case actions.FETCHED_CURRENCIES:
      let selectedCurrencyUpdate = state.selectedCurrency;
      if (!includes(action.currencies, state.selectedCurrency) && (action.currencies || []).length > 0) {
        selectedCurrencyUpdate = action.currencies[0];
      }
      return {
        ...state,
        fetchingCurrencies: false,
        fetchedCurrencies: true,
        failedFetchCurrencies: false,
        selectedCurrency: selectedCurrencyUpdate,
        currencies: action.currencies
      };
    case actions.FETCH_CURRENCIES_FAILED:
      return {
        ...state,
        fetchingCurrencies: false,
        fetchedCurrencies: false,
        failedFetchCurrencies: true
      };
    case actions.SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.currency
      };
    case actions.FETCHING_CURRENCY_STATS:
      return {
        ...state,
        fetchingCurrencyStats: true,
        fetchedCurrencyStats: false,
        failedFetchCurrencyStats: false
      };
    case actions.FETCHED_CURRENCY_STATS:
      return {
        ...state,
        fetchingCurrencyStats: false,
        fetchedCurrencyStats: true,
        failedFetchCurrencyStats: false,
        openInvoicesCurrencyStats: action.openInvoicesCurrencyStats,
        closedInvoicesCurrencyStats: action.closedInvoicesCurrencyStats,
        paymentsCurrencyStats: action.paymentsCurrencyStats
      };
    case actions.FETCH_CURRENCY_STATS_FAILED:
      return {
        ...state,
        fetchingCurrencyStats: false,
        fetchedCurrencyStats: false,
        failedFetchCurrencyStats: true
      };

    case actions.SET_WITH_COMPANY_ID:
      return { ...defaultStatementsState, withCompanyId: action.withCompanyId, companyId: state.companyId };
    case actions.CLEAR_DATA:
      return defaultStatementsState;

    case actions.SELECT_COMPANY:
      if (state.companyId === null) {
        return {
          ...state,
          companyId: action.companyId
        };
      } else if (action.companyId !== state.companyId) {
        return {
          ...defaultStatementsState,
          companyId: action.companyId
        };
      }

      return state;
    default:
      return state;
  }
}

export default statementsReducer;
