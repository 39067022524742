import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Resources from "../../lib/resources";
import { dispatchToProps } from "../../store/general-actions";

class Toast extends Component {
  render() {
    const {
      showToast,
      toastInfo: { type, text, link, linkText, linkHidden, linkAction }
    } = this.props.generalStore;
    let className = "";
    switch (type) {
      case "green":
        className = "legacy-notification-success";
        break;
      case "blue":
        className = "legacy-notification-alert";
        break;
      case "orange":
        className = "legacy-notification-warning";
        break;
      case "error":
        className = "legacy-notification-error";
        break;
      case "neutral":
      default:
        className = "legacy-notification-neutral";
    }

    let linkSpan = null;
    if (linkAction) {
      linkSpan = (
        <div
          className={className}
          style={{
            textDecoration: "underline",
            paddingLeft: "8px",
            cursor: "pointer"
          }}
          onClick={() => {
            linkAction();
            this.props.hideToast();
          }}
        >
          {linkText}
        </div>
      );
    } else if (link && linkHidden === false) {
      linkSpan = (
        <a
          className={className}
          style={{
            textDecoration: "underline",
            paddingLeft: "8px",
            cursor: "pointer"
          }}
          href={link}
          onClick={() => this.props.hideToast()}
        >
          {linkText}
        </a>
      );
    } else if (linkHidden === false) {
      linkSpan = (
        <span
          style={{ textDecoration: "underline", paddingLeft: "8px" }}
          className={"clickable " + className}
          onClick={() => {
            this.props.hideToast();
            this.props.history.goBack();
          }}
        >
          {linkText || Resources.View}
        </span>
      );
    }

    return (
      <div className={`toast legacy-notification ${className} ${showToast ? "toast-show" : ""}`}>
        {text}
        {linkSpan}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    generalStore: store.general
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(Toast)
);
