import data from "../lib/data";
import { handlePromiseError } from "./error-actions";

import { actions as GeneralActions } from "./general-actions";
import Resources from "../lib/resources";
import { isEmpty, find } from "../lib/utils";
import { getCompanyPerspectives } from "./perspectives-actions";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_CONTEXT_GROUPS: "GETTING_CONTEXT_GROUPS",
    GOT_CONTEXT_GROUPS: "GOT_CONTEXT_GROUPS",
    GET_CONTEXT_GROUPS_FAILED: "GET_CONTEXT_GROUPS_FAILED",
    UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS: "UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS",
  }
};

export const SortBy = {
  Name: "NAME",
  ActiveCount: "ACTIVE_COUNT",
  MostRecent: "MOST_RECENT",
  Hidden: "HIDDEN"
};

export const SortDirection = {
  Ascending: "ASC",
  Descending: "DESC"
};

export const fetchContextGroups = (companyId, perspectiveId) => (dispatch, getState) => {
  const store = getState().contextGroups;
  if (store.companyId === companyId && store.perspectiveId === perspectiveId && store.gettingContextGroups === true) {
    return;
  }

  dispatch({
    type: actions.GETTING_CONTEXT_GROUPS,
    companyId,
    perspectiveId
  });
  return data
    .get(`v2/api/conversation/for/${companyId}/${perspectiveId}/contextgroups`)
    .then(response => {
      dispatch({ type: actions.GOT_CONTEXT_GROUPS, companyId, perspectiveId, data: response.data });
      dispatch({
        type: actions.UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS,
        perspectiveId,
        stats: computeStatsForContextGroups(response.data)
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONTEXT_GROUPS_FAILED, companyId, perspectiveId });
      if (
        getState().contextGroups.companyId === companyId &&
        getState().contextGroups.perspectiveId === perspectiveId
      ) {
        handlePromiseError(
          response,
          "TODO: Getting the contextGroups has failed.  Please refresh the page. If this continues please let us know.",
          "context groups"
        );
      }
    });
};

export const computeStatsForContextGroups = contextGroups => {
  let unassignedActive = 0;
  let mineActive = 0;
  let assignedActive = 0;
  let pendingApproval = 0;
  let waitingResponse = 0;
  let reminders = 0; //this.props.reminders.count || 0;
  let summaryCountsMap = {};
  let badgeTypesMap = {};

  for (let i = 0; i < contextGroups.length; i++) {
    let contextGroup = contextGroups[i];
    if (contextGroup.isSpam) {
      continue;
    }
    if (contextGroup.isHidden) {
      continue;
    }
    const ac = contextGroup.activeCounts || {};
    unassignedActive += ac.unassigned || 0;
    mineActive += ac.assignedToMe || 0;
    assignedActive += ac.assignedToOthers || 0;
    pendingApproval += ac.pendingApproval || 0;
    waitingResponse += ac.waitingForResponse || 0;

    let hasTasksForUser = (ac.unassigned || 0) + (ac.assignedToMe || 0) > 0;
    if (hasTasksForUser) {
      summaryCountsMap[contextGroup.companyLocalKey] = (ac.unassigned || 0) + (ac.assignedToMe || 0);
    } else {
      summaryCountsMap[contextGroup.companyLocalKey] =
        (ac.unassigned || 0) + (ac.assignedToMe || 0) + (ac.assignedToOthers || 0) + (ac.pendingApproval || 0);
    }

    badgeTypesMap[contextGroup.companyLocalKey] = hasTasksForUser ? "alert" : "info";
  }

  summaryCountsMap.mine = mineActive;
  summaryCountsMap.unassigned = unassignedActive;
  summaryCountsMap.assigned = assignedActive;
  summaryCountsMap["waiting-for-response"] = waitingResponse;
  summaryCountsMap["pending-approval"] = pendingApproval;
  summaryCountsMap.snoozed = reminders;
  summaryCountsMap.activities = mineActive + unassignedActive;

  badgeTypesMap.mine = "alert";
  badgeTypesMap.unassigned = "alert";
  badgeTypesMap.assigned = "info";
  badgeTypesMap["waiting-for-response"] = "info";
  badgeTypesMap["pending-approval"] = "info";
  badgeTypesMap.snoozed = "info";
  badgeTypesMap.activities = "alert";
  badgeTypesMap.all = "info";

  return {
    summaryCounts: summaryCountsMap,
    badgeTypes: badgeTypesMap,
    displayActiveIndicator: summaryCountsMap.activities > 0
  };
};

export const getContextGroupName = (contextGroupId, includeId = false, unknownValue = Resources.Unknown) => (
  dispatch,
  getState
) => {
  let state = getState().contextGroups;
  if (includeId) {
    return (state.contextGroupsMaps[contextGroupId] || {}).companyName || unknownValue;
  } else {
    return (state.contextGroupsMaps[contextGroupId] || {}).displayName || unknownValue;
  }
};

export const getContextGroupNameFromCompanyId = companyId => (dispatch, getState) => {
  let state = getState().contextGroups;
  let contextGroup = find(state.value, contextGroup => companyId === contextGroup.companyId) || {};
  return contextGroup.displayName || Resources.Unknown;
};

export const dispatchToProps = dispatch => ({
  fetchContextGroups: (companyId, perspectiveId) => {
    return dispatch(fetchContextGroups(companyId, perspectiveId));
  },
  getContextGroupName: (contextGroupId, includeId, unknownValue) => {
    return dispatch(getContextGroupName(contextGroupId, includeId, unknownValue));
  },
  getContextGroupNameFromCompanyId: companyId => {
    return dispatch(getContextGroupNameFromCompanyId(companyId));
  }
});
