import data from "../lib/data";
import { isEmpty, includes } from "../lib/utils";
import { handlePromiseError } from "./error-actions";
import { actions as GeneralActions } from "./general-actions";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_ALL_SUPPORTED_CONNECTORS: "GETTING_ALL_SUPPORTED_CONNECTORS",
    GOT_ALL_SUPPORTED_CONNECTORS: "GOT_ALL_SUPPORTED_CONNECTORS",
    GET_ALL_SUPPORTED_CONNECTORS_FAILED: "GET_ALL_SUPPORTED_CONNECTORS_FAILED",

    GETTING_CONNECTOR_VERSION_DATA: "GETTING_CONNECTOR_VERSION_DATA",
    GOT_CONNECTOR_VERSION_DATA: "GOT_CONNECTOR_VERSION_DATA",
    GET_CONNECTOR_VERSION_DATA_FAILED: "GET_CONNECTOR_VERSION_DATA_FAILED",

    FETCHING_CONNECTOR_CONFIGURATION: "FETCHING_CONNECTOR_CONFIGURATION",
    FETCHED_CONNECTOR_CONFIGURATION: "FETCHED_CONNECTOR_CONFIGURATION",
    FETCH_CONNECTOR_CONFIGURATION_FAILED: "FETCH_CONNECTOR_CONFIGURATION_FAILED",

    PUTTING_CONNECTOR_CONFIGURATION: "PUTTING_CONNECTOR_CONFIGURATION",
    PUT_CONNECTOR_CONFIGURATION: "PUT_CONNECTOR_CONFIGURATION",
    FAILED_PUT_CONNECTOR_CONFIGURATION: "FAILED_PUT_CONNECTOR_CONFIGURATION",

    PUTTING_CONNECTOR_PERMISSIONS: "PUTTING_CONNECTOR_PERMISSIONS",
    PUT_CONNECTOR_PERMISSIONS: "PUT_CONNECTOR_PERMISSIONS",
    FAILED_PUT_CONNECTOR_PERMISSIONS: "FAILED_PUT_CONNECTOR_PERMISSIONS",

    CLEAR_SPECIFIC_CONNECTOR_DATA: "CLEAR_SPECIFIC_CONNECTOR_DATA"
  }
};

const fetchAllConnectors = () => (dispatch, getState) => {
  let state = getState().connector;
  if (state.isGettingAllSupportedConnectors) return;
  dispatch({ type: actions.GETTING_ALL_SUPPORTED_CONNECTORS });
  data
    .get("v1/api/connector")
    .then(response => {
      dispatch({
        type: actions.GOT_ALL_SUPPORTED_CONNECTORS,
        allSupportedConnectors: response.data
      });
    })
    .catch(error => {
      dispatch({ type: actions.GET_ALL_SUPPORTED_CONNECTORS_FAILED });
      handlePromiseError(error, "TODO: Getting connector list failed.", "connector list");
    });
};

const fetchConnectorVersionData = connectorId => (dispatch, getState) => {
  if (getState().connector.isGettingConnectorVersionData[connectorId]) {
    return;
  }
  dispatch({ type: actions.GETTING_CONNECTOR_VERSION_DATA, connectorId });
  return data
    .get(`v1/api/connector/version/${connectorId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONNECTOR_VERSION_DATA,
        connectorId,
        connectorVersionData: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_CONNECTOR_VERSION_DATA_FAILED,
        connectorId
      });
    });
};

const putConnectorConfiguration = (companyId, connectorVersionId, connectorConfig) => (dispatch, getState) => {
  if (getState().connector.isUpdatingConnectorConfig) {
    return;
  }
  dispatch({ type: actions.PUTTING_CONNECTOR_CONFIGURATION });
  return data
    .post(`v1/api/connector/config/${companyId}/${connectorVersionId}`, connectorConfig)
    .then(response => {
      dispatch({
        type: actions.PUT_CONNECTOR_CONFIGURATION,
        connectorVersionId,
        connectorConfig: response.data
      });
      return response;
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_PUT_CONNECTOR_CONFIGURATION });
    });
};

const fetchConnectorConfiguration = (companyId, connectorVersionId) => (dispatch, getState) => {
  if (isEmpty(companyId) || isEmpty(connectorVersionId)) {
    return null;
  }
  if (includes(getState().connector.fetchingConnectorConfiguration, connectorVersionId)) {
    return;
  }
  dispatch({ type: actions.FETCHING_CONNECTOR_CONFIGURATION, connectorVersionId: connectorVersionId });
  return data
    .get(`v1/api/connector/config/${companyId}/${connectorVersionId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CONNECTOR_CONFIGURATION,
        connectorVersionId,
        connectorConfig: response.data
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_CONNECTOR_CONFIGURATION_FAILED });
    });
};


export const dispatchToProps = dispatch => ({
  fetchAllConnectors: () => {
    dispatch(fetchAllConnectors());
  },
  fetchConnectorConfiguration: (companyId, connectorVersionId) => {
    return dispatch(fetchConnectorConfiguration(companyId, connectorVersionId));
  },
  putConnectorConfiguration: (companyId, connectorVersionId, connectorConfig) => {
    return dispatch(putConnectorConfiguration(companyId, connectorVersionId, connectorConfig));
  },
  fetchConnectorVersionData: connectorId => {
    return dispatch(fetchConnectorVersionData(connectorId));
  }
});
