import { actions } from "./manage-actions";

const defaultManageState = {
  companyId: null,

  isGettingAllEmailReplyTemplates: false,
  allCompanyReplyTemplates: {},
  allCompanyReplyTemplatesIsFetched: {},

  isCreatingCompanyDocument: false,
  isFetchingCompanyDocuments: false,
  fetchingCompanyDocumentsFailed: {},
  companyDocuments: {},

  puttingAddressBookContact: false,
  putAddressBookContact: false,
  failedPutAddressBookContact: false,

  postingAddressBookContact: false,
  postedAddressBookContact: false,
  failedPostAddressBookContact: false,

  companyAddressBook: [],
  isFetchingCompanyAddressBook: false,
  fetchingCompanyAddressBookFailed: null,

  deletingCompanyAddressBookContact: false,
  deletedCompanyAddressBookContact: false,
  failedDeletingCompanyAddressBookContact: false
};

const manageReducer = (state = defaultManageState, action) => {
  switch (action.type) {
    case actions.GETTING_ALL_EMAIL_REPLY_TEMPLATES:
      return { ...state, isGettingAllEmailReplyTemplates: true };
    case actions.GOT_ALL_EMAIL_REPLY_TEMPLATES:
      return {
        ...state,
        isGettingAllEmailReplyTemplates: false,
        allCompanyReplyTemplatesIsFetched: {
          ...state.allCompanyReplyTemplatesIsFetched,
          [action.perspective]: true
        },
        allCompanyReplyTemplates: {
          ...state.allCompanyReplyTemplates,
          [action.perspective]: action.companyReplyTemplates
        }
      };
    case actions.GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED:
      return {
        ...state,
        isGettingAllEmailReplyTemplates: false,
        allCompanyReplyTemplatesIsFetched: {
          ...state.allCompanyReplyTemplatesIsFetched,
          [action.perspective]: true
        }
      };

    case actions.PUTTING_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: true,
        putAddressBookContact: false,
        failedPutAddressBookContact: false
      };
    case actions.PUT_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: false,
        putAddressBookContact: true,
        failedPutAddressBookContact: false
      };
    case actions.FAILED_PUT_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: false,
        putAddressBookContact: false,
        failedPutAddressBookContact: true
      };

    case actions.POSTING_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: true,
        postedAddressBookContact: false,
        failedPostAddressBookContact: false
      };
    case actions.POST_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: false,
        postedAddressBookContact: true,
        failedPostAddressBookContact: false
      };
    case actions.FAILED_POST_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: false,
        postedAddressBookContact: false,
        failedPostAddressBookContact: true
      };

    case actions.FETCHING_COMPANY_ADDRESS_BOOK:
      return { ...state, isFetchingCompanyAddressBook: true };
    case actions.FETCHED_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        isFetchingCompanyAddressBook: false,
        fetchingCompanyAddressBookFailed: false,
        companyAddressBook: action.addresses
      };
    case actions.FETCH_COMPANY_ADDRESS_BOOK_FAILED:
      return {
        ...state,
        isFetchingCompanyAddressBook: false,
        fetchingCompanyAddressBookFailed: true
      };

    case actions.DELETING_COMPANY_ADDRESS_BOOK_CONTACT:
      return {
        ...state,
        deletingCompanyAddressBookContact: true,
        deletedCompanyAddressBookContact: false,
        failedDeletingCompanyAddressBookContact: false
      };
    case actions.DELETED_COMPANY_ADDRESS_BOOK_CONTACT:
      return {
        ...state,
        deletingCompanyAddressBookContact: false,
        deletedCompanyAddressBookContact: true,
        failedDeletingCompanyAddressBookContact: false,
        companyAddressBook: action.addresses
      };
    case actions.DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED:
      return {
        ...state,
        deletingCompanyAddressBookContact: false,
        deletedCompanyAddressBookContact: false,
        failedDeletingCompanyAddressBookContact: true
      };

    case actions.FETCHING_COMPANY_DOCUMENTS:
      return { ...state, isFetchingCompanyDocuments: true };
    case actions.FETCHED_COMPANY_DOCUMENTS:
      return {
        ...state,
        isFetchingCompanyDocuments: false,
        fetchingCompanyDocumentsFailed: {
          ...state.fetchingCompanyDocumentsFailed,
          [action.perspectiveId]: false
        },
        companyDocuments: {
          ...state.companyDocuments,
          [action.perspectiveId]: action.companyDocuments
        }
      };
    case actions.FETCH_COMPANY_DOCUMENTS_FAILED:
      return {
        ...state,
        isFetchingCompanyDocuments: false,
        fetchingCompanyDocumentsFailed: {
          ...state.fetchingCompanyDocumentsFailed,
          [action.perspectiveId]: true
        },
        companyDocuments: {
          ...state.companyDocuments,
          [action.perspectiveId]: action.companyDocuments
        }
      };

    case actions.CREATING_COMPANY_DOCUMENT:
      return { ...state, isCreatingCompanyDocument: true };
    case actions.CREATED_COMPANY_DOCUMENT:
      return { ...state, isCreatingCompanyDocument: false };
    case actions.CREATE_COMPANY_DOCUMENT_FAILED:
      return { ...state, isCreatingCompanyDocument: false };

    case actions.SELECT_COMPANY:
      return { ...defaultManageState, companyId: action.companyId };

    default:
      return state;
  }
};

export default manageReducer;
