import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "../lib/utils";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as locDP } from "../store/localization-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import Toast from "./library/toast";

import { isDebug } from "../admin/easterEggPage";
import loadable from '@loadable/component';
const Login = loadable(() => import('./login'));
const SiteDown = loadable(() => import('./siteDown'));
const ModalManager = loadable(() => import('./modalManager'));
const FlyoutManager = loadable(() => import('./flyoutManager'));
const NotificationManager = loadable(() => import('./notificationManager'));
const jwt_decode = loadable(() => import('jwt-decode'));
const EasterEggPage = loadable(() => import('../admin/easterEggPage'));
const CompanySelectRoot = loadable(() => import('../views/home/CompanySelectRoot'));
const CustomerPortalRoot = loadable(() => import('../views/home/CustomerPortalRoot'));
const PaymentConfirmationMessage = loadable(() => import('./customer_portal/paymentConfirmationMessage'));

class Layout extends Component {
  constructor(props) {
    super(props);
    this.syncToStorage = this.syncToStorage.bind(this);
  }

  syncToStorage(e) {
    if (e.key === "logged_in" && e.newValue !== "true") {
      this.props.logout();
    } else if (e.key === "id_token" && !isEmpty(e.newValue) && e.newValue !== this.props.userStore.token) {
      const prevUserId = (this.props.userStore.decoded || {}).sub;
      this.props.loggedIn(e.newValue);
      if (jwt_decode(e.newValue).sub !== prevUserId) {
        window.location.hash = "/";
        window.location.reload();
      }
    }
  }

  componentDidMount() {
    this.tryUpdate();
    this.props.isAPILive();
    window.addEventListener("storage", this.syncToStorage);
    this.props.setBrowserLanguage();
    if (this.props.isLoggedIn) {
      this.props.getAllLanguages();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.syncToStorage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoggedIn === true) {
      return;
    }
    this.tryUpdate();
  }

  tryUpdate() {
    // This only hits on mount, before user is logged in, and once right after user is logged in
    if (this.props.isLoggedIn && this.props.APILive === true) {
      this.props.getAllLanguages();
    }

    try {
      if (
        this.props.isLoggedIn !== true &&
        window.localStorage.getItem("logged_in") &&
        !window.localStorage.getItem("is_registering")
      ) {
        const idToken = window.localStorage.getItem("id_token");
        let dateNow = new Date();
        if (idToken) {
          const jwt = jwt_decode(idToken);
          if (jwt.exp >= dateNow.getTime() / 1000) {
            this.props.loggedIn(idToken);
          } else {
            this.props.logout();
          }
        }
      }
    } catch { } // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now
  }

  render() {
    // Set the default state for these in the event that localStorage is blocked by Chrome for iframe embedding
    let isLoggedIn = false;

    try {
      isLoggedIn = this.props.isLoggedIn === true || window.localStorage.getItem("logged_in");
    } catch { } // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now

    let content = () => {
      if (this.props.APILive === false) {
        return <SiteDown />;
      } else if (
        isDebug && window.location.hash.startsWith("#/easteregg")
      ) {
        return <EasterEggPage />;
      } else if (
        window.location.hash.startsWith("#/login") ||
        window.location.hash.startsWith("#/payment")) {
        return (
          <div>
            <ModalManager />
            <FlyoutManager />
            <NotificationManager />
            <Route exact path="/login" component={Login} />
            <Route exact path="/payment/method/success" component={PaymentConfirmationMessage} />
            <Route exact path="/payment/success/:transactionId" component={PaymentConfirmationMessage} />
            <Route exact path="/payment/failure/:message" component={PaymentConfirmationMessage} />
          </div>
        );
      } else if (isLoggedIn) {
        return (
          <div className="p-0 m-0 h-100">
            <ModalManager />
            <FlyoutManager />
            <NotificationManager />
            <Switch>
              <Route exact path="/" component={CompanySelectRoot} />
              {/* Customer Portal */}
              <Route path="/customer-portal/:portalView?" component={CustomerPortalRoot} />
              <Route
                path="*"
                render={props => (
                  <div id="with-side-nav-content">
                    <Toast />
                  </div>
                )}
              />
            </Switch>
          </div>
        );
      } else {
        return <Redirect to={`/login?next=${this.props.location.pathname}`} />;
      }
    };

    return content();
  }
}

const storeToProps = store => {
  return {
    isLoggedIn: store.user.isLoggedIn,
    fetchedCompanyRoles: store.accounts.fetchedCompanyRoles,
    userStore: store.user,
    APILive: store.general.isAPILive,
    languageId: store.localization.languageId
  };
};

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...locDP(dispatch),
    ...aaDP(dispatch),
    ...gaDP(dispatch)
  };
};

export default connect(storeToProps, dispatchToProps)(Layout);