import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import { isEmpty } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{

    GETTING_ALL_EMAIL_REPLY_TEMPLATES: "GETTING_ALL_EMAIL_REPLY_TEMPLATES",
    GOT_ALL_EMAIL_REPLY_TEMPLATES: "GOT_ALL_EMAIL_REPLY_TEMPLATES",
    GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED: "GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED",

    CREATING_COMPANY_DOCUMENT: "CREATING_COMPANY_DOCUMENT",
    CREATED_COMPANY_DOCUMENT: "CREATED_COMPANY_DOCUMENT",
    CREATE_COMPANY_DOCUMENT_FAILED: "CREATE_COMPANY_DOCUMENT_FAILED",

    FETCHING_COMPANY_DOCUMENTS: "FETCHING_COMPANY_DOCUMENTS",
    FETCHED_COMPANY_DOCUMENTS: "FETCHED_COMPANY_DOCUMENTS",
    FETCH_COMPANY_DOCUMENTS_FAILED: "FETCH_COMPANY_DOCUMENTS_FAILED",

    PUTTING_COMPANY_ADDRESS_BOOK: "PUTTING_COMPANY_ADDRESS_BOOK",
    PUT_COMPANY_ADDRESS_BOOK: "PUT_COMPANY_ADDRESS_BOOK",
    FAILED_PUT_COMPANY_ADDRESS_BOOK: "FAILED_PUT_COMPANY_ADDRESS_BOOK",

    POSTING_COMPANY_ADDRESS_BOOK: "POSTING_COMPANY_ADDRESS_BOOK",
    POST_COMPANY_ADDRESS_BOOK: "POST_COMPANY_ADDRESS_BOOK",
    FAILED_POST_COMPANY_ADDRESS_BOOK: "FAILED_POST_COMPANY_ADDRESS_BOOK",

    FETCHING_COMPANY_ADDRESS_BOOK: "FETCHING_COMPANY_ADDRESS_BOOK",
    FETCHED_COMPANY_ADDRESS_BOOK: "FETCHED_COMPANY_ADDRESS_BOOK",
    FETCH_COMPANY_ADDRESS_BOOK_FAILED: "FETCH_COMPANY_ADDRESS_BOOK_FAILED",

    DELETING_COMPANY_ADDRESS_BOOK_CONTACT: "DELETING_COMPANY_ADDRESS_BOOK_CONTACT",
    DELETED_COMPANY_ADDRESS_BOOK_CONTACT: "DELETED_COMPANY_ADDRESS_BOOK_CONTACT",
    DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED: "DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED",

    UPDATING_DOCUMENT_METADATA: "UPDATING_DOCUMENT_METADATA",
    UPDATED_DOCUMENT_METADATA: "UPDATED_DOCUMENT_METADATA",
    UPDATE_DOCUMENT_METADATA_FAILED: "UPDATE_DOCUMENT_METADATA_FAILED"
  }
};

const putAddressBookContact = (companyId, contact) => (dispatch, getState) => {
  if (getState().manage.puttingAddressBookContact) {
    return;
  }

  dispatch({ type: actions.PUTTING_COMPANY_ADDRESS_BOOK });

  return data
    .put(`v1/api/account/company/${companyId}/addressbook`, contact)
    .then(response => {
      dispatch({ type: actions.PUT_COMPANY_ADDRESS_BOOK });
      dispatch(fetchCompanyAddressBook(companyId));
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Adding company contact failed. Please try again", "address book");
      dispatch({ type: actions.FAILED_PUT_COMPANY_ADDRESS_BOOK });
      throw error;
    });
};

const postAddressBookContact = (companyId, addressBookContactId, contact) => (dispatch, getState) => {
  if (getState().manage.postingAddressBookContact) {
    return;
  }

  dispatch({ type: actions.POSTING_COMPANY_ADDRESS_BOOK });

  return data
    .post(`v1/api/account/company/${companyId}/addressbook/${addressBookContactId}`, contact)
    .then(response => {
      dispatch({ type: actions.POST_COMPANY_ADDRESS_BOOK });
      dispatch(fetchCompanyAddressBook(companyId));
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Editing company contact failed. Please try again", "address book");
      dispatch({ type: actions.FAILED_POST_COMPANY_ADDRESS_BOOK });
      throw error;
    });
};

const fetchCompanyAddressBook = (companyId, filter) => (dispatch, getState) => {
  if (getState().manage.isFetchingCompanyAddressBook) {
    return;
  }

  let endpoint = `v1/api/account/company/${companyId}/addressbook`;

  if (!isEmpty(filter)) {
    endpoint += `?$filter=${filter}`;
  }

  dispatch({ type: actions.FETCHING_COMPANY_ADDRESS_BOOK });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.FETCHED_COMPANY_ADDRESS_BOOK, addresses: response.data });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_COMPANY_ADDRESS_BOOK_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Fetching your company address book failed. Please try again.",
        "address book"
      );
    });
};

const deleteCompanyAddressBookContact = (companyId, addressBookContactId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_ADDRESS_BOOK_CONTACT });
  return data
    .delete(`v1/api/account/company/${companyId}/addressbook/${addressBookContactId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_COMPANY_ADDRESS_BOOK_CONTACT, addresses: response.data });
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Deleting your company address book contact failed. Please try again.",
        "address book contact"
      );
    });
};

export const getAllCompanyReplyTemplates = (companyId, perspective) => (dispatch, getState) => {
  const store = getState().manage;
  if (isEmpty(perspective)) {
    return null;
  }

  if (store.allCompanyReplyTemplatesIsFetched[perspective] !== true) {
    dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
    return null;
  }
  return store.allCompanyReplyTemplates[perspective];
};

const fetchAllCompanyReplyTemplates = (companyId, perspective) => (dispatch, getState) => {
  if (getState().manage.isGettingAllEmailReplyTemplates === true) {
    return;
  }

  dispatch({ type: actions.GETTING_ALL_EMAIL_REPLY_TEMPLATES });
  data
    .get(`v2/api/sequence/templates/${companyId}/${perspective}`)
    .then(response => {
      dispatch({
        type: actions.GOT_ALL_EMAIL_REPLY_TEMPLATES,
        perspective,
        companyReplyTemplates: response.data
      });
    })
    .catch(e => {
      dispatch({
        type: actions.GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED,
        perspective
      });
      handlePromiseError(
        e,
        "TODO: Getting list of reply templates failed.  Please refresh the page.",
        "reply templates"
      );
    });
};

const createCompanyDocument = req => dispatch => {
  const { companyId, perspectiveId, upload, metadata } = req;
  const formData = new FormData();

  formData.append("file", upload);
  dispatch({ type: actions.CREATING_COMPANY_DOCUMENT });

  return data
    .put(`v1/api/account/company/${companyId}/${perspectiveId}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.CREATED_COMPANY_DOCUMENT });

      const { companyDocumentId } = response.data;
      if (isEmpty(metadata) !== true) {
        dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
      } else {
        dispatch(fetchCompanyDocuments(companyId, perspectiveId));
      }
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.CREATE_COMPANY_DOCUMENT_FAILED });
      handlePromiseError(
        e,
        "TODO: Creating the shared document failed.  Please verify your file and try again.",
        "shared document"
      );
      throw e;
    });
};

const updateDocumentMetadata = (companyId, perspectiveId, companyDocumentId, metadata) => dispatch => {
  dispatch({ type: actions.UPDATING_DOCUMENT_METADATA });

  data
    .post(`v1/api/account/company/${companyId}/${perspectiveId}/documents/${companyDocumentId}/metadata`, metadata, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_DOCUMENT_METADATA });
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
    })
    .catch(rejection => {
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
      dispatch({ type: actions.UPDATE_DOCUMENT_METADATA_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Updating the document metadata failed.  Please verify your file and try again.",
        "document metadata"
      );
    });
};

const getCompanyDocuments = (companyId, perspectiveId) => (dispatch, getState) => {
  const store = getState().manage;
  if (store.fetchingCompanyDocumentsFailed[perspectiveId] === undefined) {
    dispatch(fetchCompanyDocuments(companyId, perspectiveId));
    return null;
  }
  return store.companyDocuments[perspectiveId];
};

const fetchCompanyDocuments = (companyId, perspectiveId) => (dispatch, getState) => {
  if (getState().manage.isFetchingCompanyDocuments === true) {
    return;
  }

  dispatch({ type: actions.FETCHING_COMPANY_DOCUMENTS });
  data
    .get(`v1/api/account/company/${companyId}/${perspectiveId}/documents`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_DOCUMENTS,
        perspectiveId,
        companyDocuments: response.data
      });
    })
    .catch(e => {
      dispatch({ type: actions.FETCH_COMPANY_DOCUMENTS_FAILED, perspectiveId });
      handlePromiseError(
        e,
        "TODO: Getting list of shared documents failed.  Please refresh the page.",
        "company documents"
      );
    });
};

export const dispatchToProps = dispatch => ({
  fetchAllCompanyReplyTemplates: (companyId, perspective) => {
    dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
  },
  putAddressBookContact: (companyId, contact) => {
    return dispatch(putAddressBookContact(companyId, contact));
  },
  postAddressBookContact: (companyId, addressBookContactId, contact) => {
    return dispatch(postAddressBookContact(companyId, addressBookContactId, contact));
  },
  fetchCompanyAddressBook: (companyId, filter) => {
    return dispatch(fetchCompanyAddressBook(companyId, filter));
  },
  deleteCompanyAddressBookContact: (companyId, addressBookContactId) => {
    return dispatch(deleteCompanyAddressBookContact(companyId, addressBookContactId));
  },
  createCompanyDocument: req => {
    return dispatch(createCompanyDocument(req));
  },
  updateDocumentMetadata: (companyId, perspectiveId, companyDocumentId, metadata) => {
    dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
  },
  fetchCompanyDocuments: (companyId, perspectiveId) => {
    dispatch(fetchCompanyDocuments(companyId, perspectiveId));
  },
  getCompanyDocuments: (companyId, perspectiveId) => {
    return dispatch(getCompanyDocuments(companyId, perspectiveId));
  },
  getAllCompanyReplyTemplates: (companyId, perspective) => {
    return dispatch(getAllCompanyReplyTemplates(companyId, perspective));
  }
});
