import { actions } from "./search-actions";
import { clone } from "../lib/utils";

const defaultSearchState = {
  isFetchingSearchResults: false,
  fetchingSearchResultsFailed: null,
  searchHistory: [],
  searchResults: [],
  searchQuery: {}
};

const searchReducer = (state = clone(defaultSearchState), action) => {
  switch (action.type) {
    case actions.FETCHING_SEARCH_RESULTS:
      return {
        ...state,
        isFetchingSearchResults: true,
        fetchingSearchResultsFailed: null,
        searchResults: [],
        searchQuery: action.request
      };
    case actions.FETCHED_SEARCH_RESULTS:
      if (action.request !== state.searchQuery) {
        return state;
      }
      return {
        ...state,
        isFetchingSearchResults: false,
        fetchingSearchResultsFailed: false,
        searchResults: action.searchResults
      };
    case actions.FETCH_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        isFetchingSearchResults: false,
        fetchingSearchResultsFailed: true
      };

    case actions.CLEAR_SEARCH_RESULTS:
      return { ...defaultSearchState, searchHistory: [...state.searchHistory] };

    default:
      return state;
  }
};

export default searchReducer;
