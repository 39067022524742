import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

import data from "../lib/data";
import {
  getAttatchedDocumentLedgerContent,
  saveBlobAsDownload
} from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{

    CREATING_DRAFT: "CREATING_DRAFT",
    CREATED_DRAFT: "CREATED_DRAFT",
    CREATE_DRAFT_FAILED: "CREATE_DRAFT_FAILED",

    FORWARDING_DRAFT: "FORWARDING_DRAFT",
    FORWARDED_DRAFT: "FORWARDED_DRAFT",
    FORWARDED_DRAFT_FAILED: "FORWARDED_DRAFT_FAILED",

    COMMITTING_DRAFT: "COMMITTING_DRAFT",
    COMMITTED_DRAFT: "COMMITTED_DRAFT",
    COMMIT_DRAFT_FAILED: "COMMIT_DRAFT_FAILED",

    CREATING_ENTRY: "CREATING_ENTRY",
    CREATED_ENTRY: "CREATED_ENTRY",
    CREATE_ENTRY_FAILED: "CREATE_ENTRY_FAILED",

    FETCHING_ENTRY: "FETCHING_ENTRY",
    GOT_ENTRY: "GOT_ENTRY",

    GETTING_ATTACHMENTS: "GETTING_ATTACHMENTS",
    GOT_ATTACHMENTS: "GOT_ATTACHMENTS",
    GET_ATTACHMENTS_FAILED: "GET_ATTACHMENTS_FAILED",

    UPLOADING_DOCUMENT: "UPLOADING_DOCUMENT",
    UPLOADED_DOCUMENT: "UPLOADED_DOCUMENT",
    UPLOAD_DOCUMENT_FAILED: "UPLOAD_DOCUMENT_FAILED",

    DELETING_ATTACHMENTS: "DELETING_ATTACHMENTS",
    DELETED_ATTACHMENTS: "DELETED_ATTACHMENTS",
    DELETE_ATTACHMENTS_FAILED: "DELETE_ATTACHMENTS_FAILED",

    ADDING_ATTACHMENT: "ADDING_ATTACHMENT",
    ADDED_ATTACHMENT: "ADDED_ATTACHMENT",
    ADD_ATTACHMENT_FAILED: "ADD_ATTACHMENT_FAILED",
    ADDED_DOCUMENT_ATTACHMENT: "ADDED_DOCUMENT_ATTACHMENT",
    GETTING_ATTACHMENTS_ZIP: "GETTING_ATTACHMENTS_ZIP",
    GOT_ATTACHMENTS_ZIP: "GOT_ATTACHMENTS_ZIP",
    GET_ATTACHMENTS_ZIP_FAILED: "GET_ATTACHMENTS_ZIP_FAILED",

    RESET_DOWNLOAD_FAILED_ATTACHMENT_STATUS:"RESET_DOWNLOAD_FAILED_ATTACHMENT_STATUS",
  }
};

const createEntryToContextGroup = (companyId, withContextGroupId, resourceName, content, headers) => (
  dispatch,
  getState
) => {
  if (getState().ledger.creatingEntry === true) {
    return;
  }
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v2/api/ledger/from/${companyId}/to/${withContextGroupId}/${resourceName}/create`, content, {
      headers: headers
    })
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      switch (resourceName) {
        case "approval":
          handlePromiseError(
            rejection,
            "TODO: Approving the transaction failed. Please refresh and try again.",
            "approval"
          );
          break;
        case "approvalRequest":
          handlePromiseError(
            rejection,
            "TODO: Creating the approval request failed. Please refresh and try again.",
            "approval request"
          );
          break;
        case "ApplicationResponse":
          handlePromiseError(rejection, "TODO: Creating the dispute failed. Please refresh and try again.", "dispute");
          break;
        case "DebitNote":
          handlePromiseError(
            rejection,
            "TODO: Creating the promise to pay failed. Please refresh and try again.",
            "promise to pay"
          );
          break;
        default:
          break;
      }
      throw rejection;
    });
};

export const createDraft = (companyId, resourceName, content) => (dispatch, getState) => {
  dispatch({ type: actions.CREATING_DRAFT });
  return data
    .put(`v1/api/ledger/from/${companyId}/draft/${resourceName}/create`, content)
    .then(res => {
      dispatch({ type: actions.CREATED_DRAFT });
      return res;
    })
    .catch(err => {
      dispatch({ type: actions.CREATE_DRAFT_FAILED });
      throw err;
    });
};

export const commitDraft = ledgerHash => (dispatch, getState) => {
  dispatch({ type: actions.COMMITTING_DRAFT });
  return data
    .post(`v1/api/ledger/draft/${ledgerHash}/commit`)
    .then(res => {
      dispatch({ type: actions.COMMITTED_DRAFT });
      return res;
    })
    .catch(err => {
      dispatch({ type: actions.COMMIT_DRAFT_FAILED });
      throw err;
    });
};

export const deleteAttachments = attachmentIds => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_ATTACHMENTS });
  let deletionPromises = attachmentIds.map(attachmentId => {
    return dispatch(deleteAttachment(attachmentId));
  });
  return Promise.all(deletionPromises)
    .then(() => {
      dispatch({ type: actions.DELETED_ATTACHMENTS });
    })
    .catch(error => {
      dispatch({ type: actions.DELETE_ATTACHMENTS_FAILED });
    });
};

export const deleteAttachment = attachmentId => (dispatch, getState) => {
  return data.delete(`v1/api/ledger/attachments/${attachmentId}`);
};

const addAttachment = (ledgerHash, file) => (dispatch, getState) => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.ADDING_ATTACHMENT });
  return data
    .post(`v1/api/ledger/attachments/${ledgerHash}`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({
        type: actions.ADDED_ATTACHMENT,
        attachment: response.data,
        ledgerHash
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.ADD_ATTACHMENT_FAILED });
      handlePromiseError(rejection, "TODO: Adding the attachment failed.  Please try again.", "attachment");
    });
};

const uploadDocument = (companyId, withContextGroupId, file) => (dispatch, getState) => {
  dispatch({ type: actions.UPLOADING_DOCUMENT });
  return dispatch(
    createEntryToContextGroup(companyId, withContextGroupId, "AttachedDocument", getAttatchedDocumentLedgerContent(), {
      "Content-Type": "application/xml"
    })
  )
    .then(result => {
      dispatch(addAttachment(result.ledgerHash, file)).then(result => {
        dispatch({ type: actions.UPLOADED_DOCUMENT });
      });
    })
    .catch(error => {
      dispatch({ type: actions.UPLOAD_DOCUMENT_FAILED });
    });
};

const addDocumentAttachment = (ledgerHash, companyDocumentId) => (dispatch, getState) => {
  return data
    .post(`v1/api/ledger/attachments/${ledgerHash}/document`, JSON.stringify(companyDocumentId), {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({
        type: actions.ADDED_DOCUMENT_ATTACHMENT,
        attachment: response.data,
        ledgerHash
      });
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Adding the attachment failed.  Please try again.", "attachment");
    });
};

let ENTRY_PROMISES = {};
export const fetchEntry = (ledgerHash, isDraft) => (dispatch, getState) => {
  let state = getState().ledger;
  let entry = state.entries[ledgerHash];
  if (entry) {
    return Promise.resolve({ data: entry });
  }

  if (ENTRY_PROMISES[ledgerHash]) return ENTRY_PROMISES[ledgerHash];

  dispatch({ type: actions.FETCHING_ENTRY, ledgerHash });

  ENTRY_PROMISES[ledgerHash] = data
    .get(`v1/api/ledger/${isDraft === true ? "draft" : "entry"}/${ledgerHash}`)
    .then(response => {
      delete ENTRY_PROMISES[ledgerHash];
      dispatch({ type: actions.GOT_ENTRY, entry: response.data, ledgerHash });
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Fetching a conversation element failed.  Please retry your request.",
        "entry"
      );
    });
  return ENTRY_PROMISES[ledgerHash];
};

export const getEntry = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  return state.entries[ledgerHash];
};

const fetchAttachments = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  let attachments = state.attachments[ledgerHash];
  if (attachments || state.gettingAttachments[ledgerHash] === true) return;

  dispatch({ type: actions.GETTING_ATTACHMENTS, ledgerHash });
  data
    .get(`v1/api/ledger/attachments/for/${ledgerHash}`)
    .then(response => {
      dispatch({
        type: actions.GOT_ATTACHMENTS,
        attachments: response.data,
        ledgerHash
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_ATTACHMENTS_FAILED, ledgerHash });
      handlePromiseError(
        response,
        "TODO: Getting the related attachments failed.  Please refresh your page.",
        "attachments"
      );
    });
};

const getAttachments = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  return state.attachments[ledgerHash];
};

const updateFetchingAttachment = () => (dispatch) =>{
  dispatch({ type: actions.GETTING_ATTACHMENTS_ZIP });
}

const updateFetchedAttachment = ()=> (dispatch) =>{
  dispatch({ type: actions.GOT_ATTACHMENTS_ZIP });
}

const resetDownloadAttachmentState = () => (dispatch) =>{
  dispatch({ type: actions.RESET_DOWNLOAD_FAILED_ATTACHMENT_STATUS });
}

const updateFetchingAttachmentFailed = ()=> (dispatch) =>{
  dispatch({ type: actions.GET_ATTACHMENTS_ZIP_FAILED });
}

const fetchAttachmentsZip = (attachmentIds, fileName) => (dispatch) => {
  dispatch({ type: actions.GETTING_ATTACHMENTS_ZIP });
  return data
    .post("v1/api/ledger/attachments/download?isPortalAttachment=true", attachmentIds, {
      responseType: "arraybuffer",
      headers: { Accept: "application/zip" }
    })
    .then(response => {
      dispatch({ type: actions.GOT_ATTACHMENTS_ZIP });
      saveBlobAsDownload(response.data, fileName);

      return true;
    })
    .catch(error => {
      dispatch({ type: actions.GET_ATTACHMENTS_ZIP_FAILED });
      return error;
    });
};

export const dispatchToProps = dispatch => ({
  fetchEntry: (ledgerHash, isDraft) => {
    return dispatch(fetchEntry(ledgerHash, isDraft));
  },
  getEntry: ledgerHash => {
    return dispatch(getEntry(ledgerHash));
  },
  createDraft: (companyId, resourceName, content) => {
    return dispatch(createDraft(companyId, resourceName, content));
  },
  commitDraft: ledgerHash => {
    return dispatch(commitDraft(ledgerHash));
  },
  createEntryToContextGroup: (companyId, withContextGroupId, resourceName, content, headers) => {
    return dispatch(createEntryToContextGroup(companyId, withContextGroupId, resourceName, content, headers));
  },
  addAttachment: (ledgerHash, file) => {
    return dispatch(addAttachment(ledgerHash, file));
  },
  uploadDocument: (companyId, withContextGroupId, file) => {
    return dispatch(uploadDocument(companyId, withContextGroupId, file));
  },
  addDocumentAttachment: (ledgerHash, companyDocumentId) => {
    return dispatch(addDocumentAttachment(ledgerHash, companyDocumentId));
  },
  deleteAttachments: attachmentIds => {
    return dispatch(deleteAttachments(attachmentIds));
  },
  deleteAttachment: attachmentId => {
    return dispatch(deleteAttachment(attachmentId));
  },
  fetchAttachments: ledgerHash => {
    dispatch(fetchAttachments(ledgerHash));
  },
  getAttachments: ledgerHash => {
    return dispatch(getAttachments(ledgerHash));
  },
  fetchAttachmentsZip: (attachmentIds, fileName) => {
    return dispatch(fetchAttachmentsZip(attachmentIds, fileName));
  },
  updateFetchingAttachment: () =>{
    return dispatch(updateFetchingAttachment());
  },
  updateFetchingAttachmentFailed: () =>{
    return dispatch(updateFetchingAttachmentFailed());
  },
  updateFetchedAttachment: () =>{
    return dispatch(updateFetchedAttachment());
  },
  resetDownloadAttachmentState: () =>{
    return dispatch(resetDownloadAttachmentState());
  }
});
