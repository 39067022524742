import { actions } from "./ledger-actions";

export const defaultLedgerState = {
  entries: {
    maxLength: 500,
    keys: []
  },
  gettingAttachments: {},
  attachments: {
    maxLength: 500,
    keys: []
  },

  uploadingDocument: false,
  uploadedDocument: false,
  uploadDocumentFailed: false,

  gettingAttachmentsZip: false,
  gotAttachmentsZip: false,
  getAttachmentsZipFailed: null,

  isAddingAttachment: false,

  deletingAttachments: false,
  deletedAttachments: false,
  deleteAttachmentsFailed: null,

  creatingEntry: false,
  createdEntry: false,
  createEntryFailed: false,

  creatingDraft: false,
  createdDraft: false,
  createDraftFailed: false,

  committingDraft: false,
  committedDraft: false,
  commitDraftFailed: false,
};

function ledgerReducer(state = defaultLedgerState, action) {
  if (window.logToConsole) {
    console.log(action);
  }

  switch (action.type) {
    case actions.SELECT_COMPANY:
      if (action.companyId === state.selectedCompanyId) {
        return state;
      }

      let companyId = action.companyId;

      if (action.companyId === "ALL_ACCOUNTS_SELECTED") {
        companyId = action.primaryCompanyId;
      }

      return { ...defaultLedgerState, selectedCompanyId: companyId };

    case actions.CREATING_ENTRY:
      return { ...state, creatingEntry: true, createdEntry: false, createEntryFailed: false };
    case actions.CREATED_ENTRY:
      return { ...state, creatingEntry: false, createdEntry: true, createEntryFailed: false };
    case actions.CREATE_ENTRY_FAILED:
      return { ...state, creatingEntry: false, createdEntry: false, createEntryFailed: true };

    case actions.CREATING_DRAFT:
      return { ...state, creatingDraft: true, createdDraft: false, createDraftFailed: false, committedDraft: false };
    case actions.CREATED_DRAFT:
      return { ...state, creatingDraft: false, createdDraft: true, createDraftFailed: false };
    case actions.CREATE_DRAFT_FAILED:
      return { ...state, creatingDraft: false, createdDraft: false, createDraftFailed: true };

    case actions.COMMITTING_DRAFT:
      return { ...state, committingDraft: true, committedDraft: false, commitDraftFailed: false };
    case actions.COMMITTED_DRAFT:
      return { ...state, committingDraft: false, committedDraft: true, commitDraftFailed: false };
    case actions.COMMIT_DRAFT_FAILED:
      return { ...state, committingDraft: false, committedDraft: false, commitDraftFailed: true };

    case actions.FETCHING_ENTRY:
      return { ...state, fetchingEntries: { ...state.fetchingEntries, [action.ledgerHash]: true } };
    case actions.GOT_ENTRY:
      const nextFetchingEntries = { ...state.fetchingEntries };
      delete nextFetchingEntries[action.ledgerHash];
      return {
        ...state,
        entries: { ...state.entries, [action.ledgerHash]: action.entry },
        fetchingEntries: nextFetchingEntries
      };

    case actions.UPLOADING_DOCUMENT:
      return { ...state, uploadingDocument: true, uploadedDocument: false, uploadDocumentFailed: false };
    case actions.UPLOADED_DOCUMENT:
      return { ...state, uploadingDocument: false, uploadedDocument: true, uploadDocumentFailed: false };
    case actions.UPLOAD_DOCUMENT_FAILED:
      return { ...state, uploadingDocument: false, uploadedDocument: false, uploadDocumentFailed: true };

    case actions.DELETING_ATTACHMENTS:
      return { ...state, deletingAttachments: true, deletedAttachments: false, deleteAttachmentsFailed: false };
    case actions.DELETED_ATTACHMENTS:
      return { ...state, deletingAttachments: false, deletedAttachments: true, deleteAttachmentsFailed: false };
    case actions.DELETE_ATTACHMENTS_FAILED:
      return { ...state, deletingAttachments: false, deletedAttachments: false, deleteAttachmentsFailed: true };

    case actions.ADDING_ATTACHMENT:
      return { ...state, isAddingAttachment: true };
    case actions.ADDED_ATTACHMENT:
      return { ...state, isAddingAttachment: false };
    case actions.ADD_ATTACHMENT_FAILED:
      return { ...state, isAddingAttachment: false };

    case actions.ADDED_DOCUMENT_ATTACHMENT:
      return { ...state };
    case actions.GETTING_ATTACHMENTS:
      let ga = { ...state.gettingAttachments };
      ga[action.ledgerHash] = true;
      return { ...state, gettingAttachments: ga };
    case actions.GET_ATTACHMENTS_FAILED:
      let gaf = { ...state.gettingAttachments };
      delete gaf[action.ledgerHash];
      return { ...state, gettingAttachments: gaf };
    case actions.GOT_ATTACHMENTS:
      if (state.gettingAttachments[action.ledgerHash] !== true) return state;

      //TODO:  WHY IS THIS IN HERE?
      if (action.type === actions.ADDED_ATTACHMENT) {
        action.attachments = [...(state.attachments[action.ledgerHash] || [])];
        action.attachments.push(action.attachment);
      }

      let gaState = { ...state };
      let gettingAttachments = { ...gaState.gettingAttachments };
      delete gettingAttachments[action.ledgerHash];
      gaState.gettingAttachments = gettingAttachments;

      if (gaState.attachments[action.ledgerHash]) {
        let uAttachments = { ...gaState.attachments };
        uAttachments[action.ledgerHash] = action.attachments;
        return { ...gaState, attachments: uAttachments };
      }

      let gsa = { ...gaState.attachments };
      gaState.attachments = gsa;
      let gaKeys = [...gsa.keys];
      gsa.keys = gaKeys;
      gaKeys.unshift(action.ledgerHash);
      gsa[action.ledgerHash] = action.attachments;
      if (gaKeys.length > gsa.maxLength) {
        let gakPopped = gaKeys.pop();
        delete gsa[gakPopped];
      }

      return gaState;

    case actions.GETTING_ATTACHMENTS_ZIP:
      return { ...state, gettingAttachmentsZip: true, gotAttachmentsZip: false, getAttachmentsZipFailed: false };
    case actions.GOT_ATTACHMENTS_ZIP:
      return { ...state, gettingAttachmentsZip: false, gotAttachmentsZip: true, getAttachmentsZipFailed: false };
    case actions.GET_ATTACHMENTS_ZIP_FAILED:
      return { ...state, gettingAttachmentsZip: false, gotAttachmentsZip: false, getAttachmentsZipFailed: true };
    case actions.RESET_DOWNLOAD_FAILED_ATTACHMENT_STATUS :
      return { ...state, getAttachmentsZipFailed: false };
    case actions.CLEAR_DATA:
      return defaultLedgerState;
    default:
      return state;
  }
}

export default ledgerReducer;
