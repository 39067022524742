import data from "../lib/data";
import Resources from "../lib/resources";
import { handlePromiseError } from "./error-actions";
import { actions as GeneralActions } from "./general-actions";
import { getConfig, isEmpty } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_COMPANY_INFO: "GETTING_COMPANY_INFO",
    GOT_COMPANY_INFO: "GOT_COMPANY_INFO",
    GET_COMPANY_INFO_FAILED: "GET_COMPANY_INFO_FAILED",

    GETTING_COMPANY: "GETTING_COMPANY",
    GOT_COMPANY: "GET_COMPANY",
    GET_COMPANY_FAILED: "GET_COMPANY_FAILED",

    DELETING_ADDRESS_FROM_SPAM_LIST: "DELETING_ADDRESS_FROM_SPAM_LIST",
    DELETED_ADDRESS_FROM_SPAM_LIST: "DELETED_ADDRESS_FROM_SPAM_LIST",
    DELETE_ADDRESS_FROM_SPAM_LIST_FAILED: "DELETE_ADDRESS_FROM_SPAM_LIST_FAILED"
  }
};

let GET_COMPANY_INFO_PROMISE = {};
const fetchCompanyInfo = companyId => (dispatch, getState) => {
  let state = getState().companies;
  if (isEmpty(companyId)) {
    return null;
  }
  if (state.gettingCompanyInfo[companyId]) return GET_COMPANY_INFO_PROMISE[companyId];
  dispatch({ type: actions.GETTING_COMPANY_INFO, companyId: companyId });
  GET_COMPANY_INFO_PROMISE[companyId] = Promise.all([
    data.get(`v1/api/account/company/${companyId}/info`),
    data.get(`v1/api/connector/email/integrations/${companyId}`)
  ])
    .then(arr => {
      delete GET_COMPANY_INFO_PROMISE[companyId];
      dispatch({
        type: actions.GOT_COMPANY_INFO,
        companyId: companyId,
        companyInfo: arr[0].data,
        connectorEmails: arr[1].data
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_COMPANY_INFO_FAILED, companyId: companyId });
      handlePromiseError(response, Resources.CompanyInfoFetchFailure, "company info");
    });
  return GET_COMPANY_INFO_PROMISE[companyId];
};

const getCompanyInfo = companyId => (dispatch, getState) => {
  let companies = getState().companies;
  if (
    companies.fetchedCompanyInfo[companyId] !== true &&
    companies.gettingCompanyInfo[companyId] !== true &&
    companies.fetchingCompanyInfoFailed !== true
  ) {
    dispatch(fetchCompanyInfo(companyId));
    return {};
  }
  let companyInfo = { ...(companies.companyInfo[companyId] || {}) };
  companyInfo.connectorEmail = {};
  companyInfo.connectorEmail.synthetic = {};
  companyInfo.connectorEmail.remote = {};
  (companyInfo.connectorEmails || []).forEach(e => {
    let synthetic = `${e.localPart}@${getConfig().emailDomain}`;
    companyInfo.connectorEmail.synthetic[e.perspectiveId] = synthetic;
    companyInfo.connectorEmail.remote[e.perspectiveId] = e.companyForwardingAddress || "";
  });

  return companyInfo;
};

export const dispatchToProps = dispatch => ({
  fetchCompanyInfo: companyId => {
    return dispatch(fetchCompanyInfo(companyId));
  },
  getCompanyInfo: companyId => {
    return dispatch(getCompanyInfo(companyId));
  }
});
