import { actions } from "./accounts-actions";
import {  find, isEmpty } from "../lib/utils";


const defaultSubscriptionState = {
  subscription: {},
  fetchingSubscription: false,
  hasFetchedSubscription: false
};

export const defaultAccountsState = {
  ...{
    fetchingAccounts: false,
    hasFetchedAccounts: false,
    hasDropdownContent: false,
    setAsAll: false,
    accounts: [],
    selectedAccount: {
      companies: [],
      features: {}
    },
    dropdownContent: {
      combinedDropdownData: []
    },
    primaryCompany: {},
    selectedCompany: {},
    ...defaultSubscriptionState
  },
};

function accountsReducer(state = defaultAccountsState, action) {
  if (window.logToConsole) {
    console.log(action);
  }

  switch (action.type) {
    case actions.FETCHING_ACCOUNTS:
      return { ...state, ...{ fetchingAccounts: true } };
    case actions.FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        ...{ fetchingAccounts: false, hasFetchedAccounts: true }
      };
    case actions.FETCHED_ACCOUNTS:
      if (state.fetchingAccounts === false) return state;
      let fetchedAccounts = action.accounts || [];
      let fetchedState = { ...state, accounts: fetchedAccounts };
      updateSelected(fetchedState);
      return {
        ...fetchedState,
        ...{ fetchingAccounts: false, hasFetchedAccounts: true }
      };

    case actions.FETCHING_SUBSCRIPTION:
      return { ...state, fetchingSubscription: true };
    case actions.FETCH_SUBSCRIPTION_FAILED:
      return {
        ...state,
        fetchingSubscription: false,
        hasFetchedSubscription: true
      };
    case actions.FETCHED_SUBSCRIPTION:
      return {
        ...state,
        fetchingSubscription: false,
        hasFetchedSubscription: true,
        subscription: action.subscription
      };

    case actions.SELECT_COMPANY:
      if (action.companyId === state.selectedCompanyId && isEmpty(state.selectedCompany) === false && !state.setAsAll) {
        return { ...state };
      }

      // If the user is working with child accounts and is trying to view all accounts, set the current state to the primary company which was
      // stored when all the child accounts were fetched.
      if (action.companyId === 'ALL_ACCOUNTS_ID') {
        return {
          ...state,
          selectedCompany: state.primaryCompany,
          selectedCompanyId: state.primaryCompany.companyId,
          setAsAll: true
        }
      }

      let selectCompanyState = {
        ...state,
        selectedCompanyId: action.companyId,
        setAsAll: false
      };

      const selectedAccount = find(state.accounts, a => {
        return !isEmpty(
          find(a.companies, c => {
            return c.companyId === action.companyId;
          })
        );
      });

      updateSelected(selectCompanyState);

      return {
        ...selectCompanyState,
        ...defaultSubscriptionState,
        selectedAccount
      };

    case actions.FETCH_DROPDOWN_CONTENT:
      return { ...state, dropdownContent: action.data, hasDropdownContent: true, primaryCompany: action.primaryCompany }
    case actions.UPDATE_DROPDOWN_SORT:
      return {
        ...state,
        dropdownContent: updateDropDownSort(state, action.data)
      };

    case actions.CLEAR_DATA:
      return defaultAccountsState;
    default:
      return state;
  }
}

const updateDropDownSort = (state, array) => {
  return {
    combinedDropdownData: array,
    longestCustomerName: state.dropdownContent.longestCustomerName,
    allAccountObj: state.dropdownContent.allAccountObj
  }
}

function updateSelected(state) {
  let accounts = state.accounts || [];
  const selectedAccountId = state.selectedAccountId || (accounts[0] || {}).accountId;

  let foundAccount = find(accounts, a => {
    return selectedAccountId === a.accountId;
  });

  state.selectedAccountId = (foundAccount || {}).accountId;
  state.selectedAccount = foundAccount || {};

  let companies = [];

  accounts.forEach(account => {
    companies = [...companies, ...account.companies];
  });

  let foundCompany = find(companies, c => {
    return state.selectedCompanyId && state.selectedCompanyId === c.companyId;
  });

  state.selectedCompany = foundCompany || {};
}

export default accountsReducer;
