import { actions } from "./user-actions";
import jwt_decode from "jwt-decode";

const defaultCompanyUsersState = {
  usersCompanyId: null,
  gettingUsers: false,
  fetchedUsers: false,
  users: [],
  userLookup: {}
};

const defaultUserState = {
  isLoggedIn: false,
  isLoggingIn: false,
  isSamlLogin: false,
  isLoggingOut: false,
  didLoginFail: false,
  isTokenExpiring: false,

  gettingUserConfig: false,
  fetchedUserConfig: false,
  userConfig: {},
  zenDeskConfig: {},
  ...defaultCompanyUsersState
};

const userReducer = (state = defaultUserState, action) => {
  switch (action.type) {
    case actions.LOGGING_IN:
      return {
        ...state,
        ...{ isLoggingIn: true, didLoginFail: action.didLoginFail, isSamlLogin: action.samlLogin }
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        ...{ isLoggingIn: false, didLoginFail: action.didLoginFail }
      };
    case actions.LOGGED_IN:
      let decoded = null;
      if (action.token) decoded = jwt_decode(action.token);
      return {
        ...state,
        ...{
          isLoggingIn: false,
          isLoggedIn: true,
          token: action.token,
          decoded: decoded,
          isTokenExpiring: false,
        }
      };

    case actions.TOKEN_EXPIRING:
      return { ...state, isTokenExpiring: true };
    case actions.LOGGING_OUT:
      return {
        ...state,
        ...{ isLoggingOut: true, isLoggedIn: false, token: null, decoded: null }
      };
    case actions.LOGOUT_FAILED:
      return { ...state, ...{ isLoggingOut: false } };
    case actions.LOGGED_OUT:
      return {
        ...state,
        ...{
          isLoggingOut: false,
          isLoggedIn: false,
          token: null,
          decoded: null
        }
      };

    case actions.GETTING_USER_CONFIG:
      return {
        ...state,
        ...{ gettingUserConfig: true, fetchedUserConfig: false }
      };
    case actions.GET_USER_CONFIG_FAILED:
      return {
        ...state,
        ...{ gettingUserConfig: false, fetchedUserConfig: true }
      };
    case actions.GOT_USER_CONFIG:
      return {
        ...state,
        ...{
          gettingUserConfig: false,
          fetchedUserConfig: true,
          userConfig: action.config
        }
      };
      
    case actions.SELECT_COMPANY:
      if (state.usersCompanyId !== action.companyId) return { ...state, ...defaultCompanyUsersState };
      else return state;

    case actions.CLEAR_DATA:
      return defaultUserState;

    case actions.GOT_USER_ZENDESK_CONFIG:
      return {
        ...state,
        ...{ zenDeskConfig: action.config }
      };
    default:
      return state;
  }
};

export default userReducer;
