import { actions as GeneralActions } from "./general-actions";

export const actions = {
  ...GeneralActions,
  ...{
    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL",
    SHOW_FLYOUT: "SHOW_FLYOUT",
    HIDE_FLYOUT: "HIDE_FLYOUT",
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
    HIDE_NOTIFICATION: "HIDE_NOTIFICATION"
  }
};

export const hideModal = () => dispatch => {
  return dispatch({ type: actions.HIDE_MODAL });
};

export const displayNotification = (notificationType, notificationProps) => dispatch => {
  dispatch({ type: actions.SHOW_NOTIFICATION, notificationType, notificationProps });
};

export const dispatchToProps = dispatch => ({
  displayModal: (modalType, modalProps) => {
    return dispatch({ type: actions.SHOW_MODAL, modalType, modalProps });
  },
  hideModal: () => {
    return dispatch(hideModal());
  },
  displayFlyout: (flyoutType, flyoutProps) => {
    return dispatch({ type: actions.SHOW_FLYOUT, flyoutType, flyoutProps });
  },
  hideFlyout: () => {
    return dispatch({ type: actions.HIDE_FLYOUT });
  },
  displayNotification: (notificationType, notificationProps) => {
    return dispatch({ type: actions.SHOW_NOTIFICATION, notificationType, notificationProps });
  },
  hideNotification: () => {
    return dispatch({ type: actions.HIDE_NOTIFICATION });
  }
});
