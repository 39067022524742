import { actions } from "./company-actions";

const defaultCompaniesState = {
  gettingCompanyInfo: {},
  fetchedCompanyInfo: {},
  companyInfo: {},
  fetchingCompanyInfoFailed: {},

  gettingCompany: {},
  fetchedCompany: {},
  failedCompany: {}
};

function companyReducer(state = defaultCompaniesState, action) {
  switch (action.type) {
    case actions.GETTING_COMPANY_INFO:
      return {
        ...state,
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: true
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: false
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: null
        }
      };
    case actions.GET_COMPANY_INFO_FAILED:
      return {
        ...state,
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: false
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: false
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: true
        }
      };
    case actions.GOT_COMPANY_INFO:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          [action.companyId]: {
            ...action.companyInfo,
            connectorEmails: action.connectorEmails
          }
        },
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: false
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: true
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: false
        }
      };

    case actions.GETTING_COMPANY:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: true },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: false }
      };
    case actions.GET_COMPANY_FAILED:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: false },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: false },
        failedCompany: { ...state.failedCompany, [action.companyId]: true }
      };
    case actions.GOT_COMPANY:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: false },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: true },
        failedCompany: { ...state.failedCompany, [action.companyId]: false }
      };

    case actions.CLEAR_DATA:
      return defaultCompaniesState;
    default:
      return state;
  }
}

export default companyReducer;
