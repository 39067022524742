import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import jwt_decode from "jwt-decode";
import { Provider, useSelector } from "react-redux";
import { Router, HashRouter, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import store from "./store/store";
import { dispatchToProps as UserDispatch } from "./store/user-actions";
import {
  getConfig,
  getQueryParameters,
  isLocalHost,
  isProduction,
  isStaging,
  isQA,
  isLeading,
  isTrailing,
} from "./lib/utils";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import FullStory from "react-fullstory";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import { createHashHistory } from "history";

import "moment/min/locales.min";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "./css/index.scss";

import Layout from "./components/layout";
import ErrorBoundary from "./components/errorBoundary";
import { Tracking } from "./components/tracking";

let history = createHashHistory();

const ZendeskChatLoader = () => {
  const accountStore = useSelector((state) => state.accounts);
  const { zenDeskConfig } = useSelector((state) => state.user);

  const loadZendeskScript = (token, url, chatAgent) => {
    const script = document.createElement("script");

    const scriptId = "ze-snippet";

    switch (chatAgent) {
      case 1: {
        // Display Web Widget
        const script1 = document.createElement("script");
        script1.setAttribute("id", scriptId);
        script1.setAttribute("src", url);

        const script2 = document.createElement("script");
        script2.textContent = `zE('messenger', 'loginUser', function (callback) { callback('${token}');});`;

        document.head.appendChild(script1);
        document.head.appendChild(script2);
        break;
      }
      case 2: {
        const script1 = document.createElement("script");
        script1.setAttribute("id", scriptId);
        script1.setAttribute("src", url);

        const script2 = document.createElement("script");
        script2.textContent = `window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: function (callback) {
                callback('${token}');
              }
            }
          },
          contactForm: {
            fields: [
              { id: 'subject', prefill: { '*': 'Request subject here' } },
              { id: 'description', prefill: { '*': 'Request description here' } }
            ]
          }
        }
      };`;

        document.head.appendChild(script1);
        document.head.appendChild(script2);
        break;
      }

      default:
        break;
    }
    document.body.appendChild(script);
  };

  useEffect(() => {
    let isChatActive = accountStore?.selectedAccount?.features?.chatAgent;
    if (isChatActive && isChatActive > 0) {
      UserDispatch(store.dispatch).getZenDeskConfig();
    }
  }, [accountStore?.selectedAccount?.features?.chatAgent]);

  useEffect(() => {
    if (zenDeskConfig.token && zenDeskConfig.url) {
      const chatAgent = accountStore?.selectedAccount?.features?.chatAgent;
      const { token, url } = zenDeskConfig;
      loadZendeskScript(token, url, chatAgent);
    }
  }, [zenDeskConfig]);

  return null;
};

if (window.location.hash && window.location.hash !== "") {
  let res = getQueryParameters(window.location.hash.replace(/^#\/?/, ""));

  if (res.id_token) {
    window.localStorage.setItem("id_token", res.id_token);
    UserDispatch(store.dispatch).loggedIn(res.id_token);
    window.location = "#/";
  }

  if (res.saml) {
    window.localStorage.clear();
    window.localStorage.setItem("saml", res.saml);
    window.localStorage.setItem("saml-provider", res.provider);
    if (res.CustPage) {
      window.localStorage.setItem("cust-page", res.CustPage);
    }
  }
}

try {
  let idToken = window.localStorage.getItem("id_token");
  let dateNow = new Date();
  if (idToken) {
    let jwt = jwt_decode(idToken);
    if (jwt.exp >= dateNow.getTime() / 1000) {
      UserDispatch(store.dispatch).loggedIn(idToken);
    } else {
      UserDispatch(store.dispatch).logout();
    }
  }

  let samlSession = window.localStorage.getItem("saml");
  let providerSession = window.localStorage.getItem("saml-provider");
  if (samlSession && providerSession) {
    UserDispatch(store.dispatch).samlLogin(providerSession, samlSession);
  }
} catch {} // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now

if (getConfig().forceEnableFullStory || (isLocalHost() !== true && isProduction())) {
  const fullStoryRoot = createRoot(document.getElementById("fullstory-node"));
  fullStoryRoot.render(<FullStory org={getConfig().fullstoryId} />);
}

//Launch Darkly Initialization
(async () => {
  let clientSideID = "5fadac306caa1f0a1a58ef95";
  if (isStaging()) {
    clientSideID = "5fadac55bb17700a04240e69";
  }
  if (isQA()) {
    clientSideID = "5fadac11c1f7360a5eb20d68";
  }
  if (isLeading()) {
    clientSideID = "5fbd944e943b4e09a2297be4";
  }
  if (isTrailing()) {
    clientSideID = "5fbd9467b051d70981ba5d4e";
  }
  if (isProduction()) {
    clientSideID = "5fadac11c1f7360a5eb20d69";
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideID,
    user: {
      key: "anon",
      name: "Anonymous",
    },
  });

  const appRoot = createRoot(document.getElementById("root"));
  appRoot.render(
    <LDProvider>
      <Provider store={store}>
        <Router history={history}>
          <HashRouter>
            <ErrorBoundary>
              <Tracking />
              <Helmet>
                <title>Lockstep</title>
              </Helmet>
              <DndProvider backend={Backend}>
                <ZendeskChatLoader />
                <Route path="/" component={Layout} />
              </DndProvider>
            </ErrorBoundary>
          </HashRouter>
        </Router>
      </Provider>
    </LDProvider>
  );
})();
