import { actions } from "./perspectives-actions";
import { find } from "../lib/utils";

export const defaultPerspectivesState = {
  perspectives: [],

  isFetchingCompanyPerspectives: false,
  hasFetchedCompanyPerspective: false,
  fetchingCompanyPerspectivesFailed: null,
  companyPerspectives: [],
  companyPerspectivesMap: {},

  companyId: null,
  selectedPerspectiveId: null,
  selectedPerspective: null,

  // vendors = 1 and customers = 2
  selectedPerspectiveType: null
};

function perspectivesReducer(state = defaultPerspectivesState, action) {
  if (window.logToConsole) console.log(action);
  switch (action.type) {
    case actions.FETCHING_COMPANY_PERSPECTIVES:
      return { ...state, isFetchingCompanyPerspectives: true };
    case actions.FETCHED_COMPANY_PERSPECTIVES:
      const companyPerspectivesMap = {};
      action.companyPerspectives.forEach(p => (companyPerspectivesMap[p.perspectiveId] = p));
      return {
        ...state,
        isFetchingCompanyPerspectives: false,
        hasFetchedCompanyPerspective: true,
        fetchingCompanyPerspectivesFailed: false,
        companyPerspectives: action.companyPerspectives,
        companyPerspectivesMap
      };
    case actions.FETCH_COMPANY_PERSPECTIVES_FAILED:
      return {
        ...state,
        fetchingCompanyPerspectivesFailed: true,
        hasFetchedCompanyPerspective: true,
        isFetchingCompanyPerspectives: false
      };

    case actions.SET_SELECTED_PERSPECTIVE_ID:
      let selectedPerspective =
        find(state.perspectives, perspective => perspective.perspectiveId === action.perspectiveId) ||
        find(state.companyPerspectives, perspective => perspective.perspectiveId === action.perspectiveId) ||
        {};
      return {
        ...state,
        selectedPerspectiveId: action.perspectiveId,
        selectedPerspective,
        selectedPerspectiveType: (selectedPerspective.parentPerspective || {}).perspectiveName
      };

    case actions.CLEAR_DATA:
      return defaultPerspectivesState;

    case actions.SELECT_COMPANY:
      if (state.companyId === null) {
        return {
          ...state,
          companyId: action.companyId
        };
      } else if (action.companyId !== state.companyId) {
        return {
          ...defaultPerspectivesState,
          companyId: action.companyId
        };
      }

      return state;

    default:
      return state;
  }
}

export default perspectivesReducer;
