import Axios from "axios";

import { getConfig, isEmpty } from "./utils";

export const dataAxios = Axios.create({
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "-1" }
});

function defaultOptions() {
  let config = getConfig();
  let headers = {};
  if (config.AccessToken) {
    headers["Authorization"] = `Bearer ${config.AccessToken}`;
  }
  return { headers: headers };
}

function mergeOptions(options) {
  let def = defaultOptions();
  Object.keys(options || {}).forEach(key => {
    def[key] = def[key] ? { ...(def[key] || {}), ...options[key] } : options[key];
  });
  return def;
}

dataAxios.interceptors.request.use(
  function(config) {
    if (navigator.onLine === false) {
      return Promise.reject(new Error("Network error"));
    }
    if (config.method === "get") {
      config.retry = 4;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

dataAxios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  const RETRY_DELAYS = [1, 2, 4, 8, 16];
  const config = err.config;

  const isRetryable =
    !isEmpty(config) && !isEmpty(config.retry) && !isEmpty(err.response) && err.response.status >= 500;
  // If config does not exist or the retry option is not set, reject
  if (isRetryable !== true) {
    return Promise.reject(err);
  }

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  const retryDelay = RETRY_DELAYS[config.__retryCount];
  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  const backoff = new Promise(function(resolve) {
    setTimeout(function() {
      resolve();
    }, retryDelay * 1000);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function() {
    return dataAxios(config);
  });
});

function get(url, options) {
  return dataAxios.get(getConfig().apiEndpoint + "/" + url, mergeOptions(options));
}

function deleteItem(url, options) {
  return dataAxios.delete(getConfig().apiEndpoint + "/" + url, mergeOptions(options));
}

function createItem(url, data, options) {
  return dataAxios.post(getConfig().apiEndpoint + "/" + url, data, mergeOptions(options));
}

function editItem(url, data, options) {
  return dataAxios.put(getConfig().apiEndpoint + "/" + url, data, mergeOptions(options));
}

export const swrFetcher = url => get(url).then(res => res.data);

export default {
  get,
  put: editItem,
  post: createItem,
  delete: deleteItem
};
