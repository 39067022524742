import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,

  SET_SELECTED_PERSPECTIVE_ID: "SET_SELECTED_PERSPECTIVE_ID",
  FETCHING_COMPANY_PERSPECTIVES: "FETCHING_COMPANY_PERSPECTIVES",
  FETCHED_COMPANY_PERSPECTIVES: "FETCHED_COMPANY_PERSPECTIVES",
  FETCH_COMPANY_PERSPECTIVES_FAILED: "FETCH_COMPANY_PERSPECTIVES_FAILED"
};

const fetchCompanyPerspectives = companyId => (dispatch, getState) => {
  if (getState().perspectives.isFetchingCompanyPerspectives) {
    return;
  }
  dispatch({ type: actions.FETCHING_COMPANY_PERSPECTIVES });
  data
    .get(`v1/api/perspective/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_PERSPECTIVES,
        companyPerspectives: response.data
      });
      // return response.data
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_COMPANY_PERSPECTIVES_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Fetching company perspectives failed.  Please refresh the page and try again.",
        "company workspaces"
      );
    });
};

export const getCompanyPerspectives = companyId => (dispatch, getState) => {
  const { fetchingCompanyPerspectivesFailed, companyPerspectives } = getState().perspectives;
  if (fetchingCompanyPerspectivesFailed === null) {
    dispatch(fetchCompanyPerspectives(companyId));
    return null;
  }
  return companyPerspectives;
};

export const dispatchToProps = dispatch => ({
  setSelectedPerspectiveId: perspectiveId => {
    dispatch({ type: actions.SET_SELECTED_PERSPECTIVE_ID, perspectiveId });
  },
  fetchCompanyPerspectives: companyId => {
    dispatch(fetchCompanyPerspectives(companyId));
  },
  getCompanyPerspectives: companyId => {
    return dispatch(getCompanyPerspectives(companyId));
  }
});
