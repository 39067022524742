import React, { Component } from "react";
import Resources from "../lib/resources";
import { FullStoryAPI } from "react-fullstory";
import { isLocalHost, isProduction } from "../lib/utils";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (isLocalHost() !== true && isProduction()) {
      FullStoryAPI("log", "error", error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex-center align-center h-75">
          <div className="flex-column flex-center text-center mb-5">
            <h1 className="warning-color-font">{Resources.UhOh}</h1>
            <h1 className="warning-color-font mb-5">{Resources.SomethingWentWrong}</h1>
            <h4 className="mb-3">{Resources.OurEngineersHaveBeenNotified}</h4>
            <h4>
              {Resources.TryAgainInAWhile}{" "}
              <a className="blue-link-white-bg" href="/">
                {Resources.ReturnToHomePage}
              </a>
            </h4>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
