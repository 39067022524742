const icons = {
  activeConversation: "fas fa-circle",
  attachment: "fal fa-paperclip", 
  close: "fal fa-times",
  dropdown: "fas fa-angle-down",
  exclamationCircle: "fas fa-exclamation-circle",
  fileAlt: "far fa-file-alt",
  fileImage: "far fa-file-image",
  filePdf: "far fa-file-pdf",
  fileWord: "far fa-file-word",
  pencil: "fas fa-pencil",
  plus: "fas fa-plus-circle",
  search: "fas fa-search",
  spinner: "fas fa-spinner",
  times: "fal fa-times"
};

export default icons;
