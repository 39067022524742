import data from "../lib/data";
import { handlePromiseError } from "./error-actions";
import { actions as GeneralActions } from "./general-actions";
import { isEmpty, isGuid, removeLeading } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_CONVERSATION_STATUSES: "GETTING_CONVERSATION_STATUSES",
    GOT_CONVERSATION_STATUSES: "GOT_CONVERSATION_STATUSES",
    GET_CONVERSATION_STATUSES_FAILED: "GET_CONVERSATION_STATUSES_FAILED",

    GETTING_CONVERSATIONS: "GETTING_CONVERSATIONS",
    GOT_CONVERSATIONS: "GOT_CONVERSATIONS",
    GET_CONVERSATIONS_FAILED: "GET_CONVERSATIONS_FAILED",

    GETTING_CONVERSATION: "GETTING_CONVERSATION",
    GOT_CONVERSATION: "GOT_CONVERSATION",
    GET_CONVERSATION_FAILED: "GET_CONVERSATION_FAILED",

    UPDATING_CONVERSATION_READ_STATUSES: "UPDATING_CONVERSATION_READ_STATUSES",
    UPDATED_CONVERSATION_READ_STATUSES: "UPDATED_CONVERSATION_READ_STATUSES",
    UPDATE_CONVERSATION_READ_STATUSES_FAILED: "UPDATE_CONVERSATION_READ_STATUSES_FAILED",

    CLEAR_CONVERSATION: "CLEAR_CONVERSATION",
    GOT_RELATED: "GOT_RELATED",

    SETTING_CONVERSATION_STATUSES: "SETTING_CONVERSATION_STATUSES",
    SET_CONVERSATION_STATUSES: "SET_CONVERSATION_STATUSES",
    SET_CONVERSATION_STATUSES_FAILED: "SET_CONVERSATION_STATUSES_FAILED",
    SET_CONVERSATION_SORT: "SET_CONVERSATION_SORT",

    SET_DOCUMENT_SORT: "SET_DOCUMENT_SORT",
    GETTING_DOCUMENTS: "GETTING_DOCUMENTS",
    GOT_DOCUMENTS: "GOT_DOCUMENTS",
    GET_DOCUMENTS_FAILED: "GET_DOCUMENTS_FAILED",

    SET_CONVERSATIONS_TO_SEARCH_RESULT: "SET_CONVERSATIONS_TO_SEARCH_RESULT",
    CLEAR_CONVERSATIONS: "CLEAR_CONVERSATIONS",
    SET_CONVERSATIONS_READ_STATUS: "SET_CONVERSATIONS_READ_STATUS",

    GETTING_MESSAGES: "GETTING_MESSAGES",
    GOT_MESSAGES: "GOT_MESSAGES",
    GET_MESSAGES_FAILED: "GET_MESSAGES_FAILED",

    GETTING_MESSAGE_NOTIFICATIONS: "GETTING_MESSAGE_NOTIFICATIONS",
    GOT_MESSAGE_NOTIFICATIONS: "GOT_MESSAGE_NOTIFICATIONS",
    GET_MESSAGE_NOTIFICATIONS_FAILED: "GET_MESSAGE_NOTIFICATIONS_FAILED",
  }
};

const getConversationStatuses = companyId => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.companyId && state.companyId !== companyId) return;

  if (state.gettingStatuses) return;
  dispatch({ type: actions.GETTING_CONVERSATION_STATUSES, companyId });
  data
    .get(`v1/api/conversation/status/for/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATION_STATUSES,
        statuses: response.data,
        companyId
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONVERSATION_STATUSES_FAILED, companyId });
      handlePromiseError(
        response,
        "TODO: Getting the list of conversation statuses for the company failed.  Please refresh the page.",
        "activity statuses"
      );
    });
};

const ensureConversationStatuses = () => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.fetchedStatuses !== true && state.gettingStatuses !== true && isEmpty(state.companyId) === false)
    dispatch(getConversationStatuses(state.companyId));
};

const getConversationStatus = statusId => (dispatch, getState) => {
  let statusesLookup = getState().conversations.statusesLookup;
  return statusesLookup[statusId];
};

const getLabeledWithCompanyConversations = (companyId, perspective, withCompanyId, label, top, skip) => (
  dispatch,
  getState
) => {
  let state = getState().conversations;
  if (state.companyId && state.companyId !== companyId) return;

  if (state.withCompanyId === withCompanyId && state.gettingConversations[perspective] && state.label === label) {
    return;
  }

  const gettingAction = {
    type: actions.GETTING_CONVERSATIONS,
    companyId,
    perspective,
    withCompanyId,
    label
  };

  if (label !== state.label) {
    gettingAction.clearConversations = true;
  }

  dispatch(gettingAction);
  data
    .get(
      `v2/api/conversation/for/${companyId}/${perspective}/with/${withCompanyId}?$orderby=${state.sortBy}%20${state.sortDirection
      }&$top=${top || getState().general.pageRowCount}&$label=${label}${isEmpty(skip) ? "" : `&$skip=${skip}`}`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATIONS,
        conversations: response.data,
        label,
        companyId,
        perspective,
        withCompanyId
      });
    })
    .catch(response => {
      dispatch({
        label,
        type: actions.GET_CONVERSATIONS_FAILED,
        companyId,
        perspective,
        withCompanyId
      });
      handlePromiseError(
        response,
        "TODO: Getting the list of conversations failed.  Please refresh the page.",
        "conversations"
      );
    });
};

const getConversation = conversationId => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.gettingConversation) return;

  dispatch({ type: actions.GETTING_CONVERSATION, conversationId });
  return data
    .get(`v1/api/conversation/${conversationId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATION,
        conversation: response.data,
        conversationId
      });
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONVERSATION_FAILED, conversationId });
      handlePromiseError(response, "TODO: Getting the conversation failed.  Please try again.", "conversation");
    });
};

export const refreshConversation = () => (dispatch, getState) => {
  let state = getState().conversations;
  if (isEmpty(state.conversationId) === true || state.conversationId === "new") return;

  return dispatch(getConversation(state.conversationId));
};

let RELATED_PROMISES = {};
const fetchRelated = addressId => (dispatch, getState) => {
  let state = getState().conversations;
  let related = state.related[addressId];
  if (related) {
    return Promise.resolve({ data: related });
  }

  if (RELATED_PROMISES[addressId]) return RELATED_PROMISES[addressId];

  RELATED_PROMISES[addressId] = data
    .get(`v2/api/conversation/about/${addressId}?$top=${getState().general.pageRowCount}&$orderby=modifiedDate%20desc`)
    .then(response => {
      delete RELATED_PROMISES[addressId];
      dispatch({
        type: actions.GOT_RELATED,
        related: response.data,
        addressId
      });
    })
    .catch(response => {
      handlePromiseError(
        response,
        "TODO: Getting related conversations failed.  Please refresh the page.",
        "related conversations"
      );
    });
  return RELATED_PROMISES[addressId];
};

const getRelated = addressId => (dispatch, getState) => {
  let state = getState().conversations;
  return state.related[addressId];
};

let SET_CONVERSATION_STATUSES_PROMISE = null;
const setConversationStatusesTo = (conversations, statusId, suppressError) => (dispatch, getState) => {
  let items = Array.isArray(conversations) ? conversations : [conversations];
  if (isEmpty(items)) throw new Error("invalid parameter type for conversations");
  if (isEmpty(SET_CONVERSATION_STATUSES_PROMISE) !== true) throw new Error("already setting status...you must wait");

  let statusToConversations = {};
  items.forEach(conversation =>
    statusToConversations[conversation.conversationStatusId]
      ? statusToConversations[conversation.conversationStatusId].push(conversation)
      : (statusToConversations[conversation.conversationStatusId] = [conversation])
  );

  dispatch({ type: actions.SETTING_CONVERSATION_STATUSES });
  SET_CONVERSATION_STATUSES_PROMISE = Promise.all(
    Object.keys(statusToConversations).map(key => {
      let fromConversationStatusId = statusToConversations[key][0].conversationStatusId;
      return data.post(`v1/api/conversation/status/workflow/advance/bulk`, {
        conversationIds: statusToConversations[key].map(conversation => conversation.conversationId),
        fromConversationStatusId,
        toConversationStatusId: statusId
      });
    })
  )
    .then(responses => {
      SET_CONVERSATION_STATUSES_PROMISE = null;
      dispatch({
        type: actions.SET_CONVERSATION_STATUSES,
        conversations: responses.map(response => {
          return response.data;
        })
      });
      return true;
    })
    .catch(rejection => {
      dispatch({ type: actions.SET_CONVERSATION_STATUSES_FAILED });
      if (suppressError !== true) {
        handlePromiseError(
          rejection,
          "TODO: Setting the conversation status failed.  Please refresh the page and try again.",
          "conversation status"
        );
      }

      throw rejection;
    });
  return SET_CONVERSATION_STATUSES_PROMISE;
};

export const getConversationDocuments = (companyId, selector, next, perspective, top, skip) => (dispatch, getState) => {
  let state = getState().conversations.documents;

  let selectorIsGuid = isGuid(selector);

  // label acts as the withCompanyId or the label. ie. unassigned or mine
  let queryCompanyId = selectorIsGuid ? selector : undefined;

  let nextLink = next !== true ? null : removeLeading("/", state.nextLink);
  if (next === true && isEmpty(nextLink)) return;

  dispatch({ type: actions.GETTING_DOCUMENTS, companyId, selector, perspectiveId: perspective, next });
  return data
    .get(
      nextLink ||
      `v2/api/ledger/attachments/list/${companyId}${selectorIsGuid ? `/with/${queryCompanyId}?` : "?"}$orderby=${state.sortBy
      }%20${state.sortDirection}&$top=${top || getState().general.pageRowCount}${selectorIsGuid ? "" : `&$label=${selector}`
      }&$filter=perspectiveid%20eq%20${perspective}${isEmpty(skip) ? "" : `&$skip=${skip}`}`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_DOCUMENTS,
        documents: response.data.value,
        companyId,
        selector,
        nextLink: response.data.nextLink,
        count: response.data.count,
        perspectiveId: perspective
      });
      return response.data.value;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Getting the list of documents failed.  Please refresh the page.", "documents");
      dispatch({ type: actions.GET_DOCUMENTS_FAILED, companyId, selector, perspectiveId: perspective });
    });
};

export const updateConversationReadStatuses = (conversationIds, isRead) => (dispatch, getState) => {
  let state = getState().conversations;

  if (state.isUpdatingConversationReadStatuses) {
    return;
  }

  dispatch({ type: actions.UPDATING_CONVERSATION_READ_STATUSES });

  return data
    .post("v2/api/conversation/read", { conversationIds, isRead })
    .then(response => {
      // This action will update the new store property for conversation data used by the updated conversation table component. Eventually once we
      // are using a single store property for conversation data, one of these dispatch calls can be removed.
      dispatch({
        type: actions.SET_CONVERSATIONS_READ_STATUS,
        conversationIds,
        isRead
      });

      dispatch({
        type: actions.UPDATED_CONVERSATION_READ_STATUSES,
        conversationIds,
        isRead
      });

      return true;
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_CONVERSATION_READ_STATUSES_FAILED });
      handlePromiseError(
        error,
        "TODO: Updating conversation read status failed, please try again or notify us if the issue persists."
      );
      throw error;
    });
};

export const getMessages = ({
  GroupKey,
  ActivityType,
  CustomerKey,
  UserKey,
  IsInbox,
  IsSent,
  IsArchive,
  top,
  skip,
}) => async (dispatch, getState) => {
  let state = getState().conversations;
  if (state.gettingMessage) return;
  dispatch({ type: actions.GETTING_MESSAGES });
  
  try {
    const portalMessage = {
      GroupKey: GroupKey,
      ActivityType: ActivityType,
      CustKey: CustomerKey,
      UserKey: UserKey,
      IsInbox: IsInbox,
      IsSent: IsSent,
      IsArchive: IsArchive
    };

    const response = await data.post(
      `v4/api/accountoverview/portalmessage/for/${GroupKey}?&$top=${top || 20}${isEmpty(skip) ? "" : `&$skip=${skip}`}`,
      portalMessage
    );

    dispatch({
      type: actions.GOT_MESSAGES,
      messagesRowCount : response.data.count,
      activityMessages: response.data.value
    });
  } catch (error) {
    dispatch({
      type: actions.GET_MESSAGES_FAILED,
      error: error.message
    });
    throw error;
  }
};

export const getMessagesNotification = ({
  GroupKey,
  ActivityType,
  CustomerKey,
  UserKey,
}) => async (dispatch, getState) => {
  let state = getState().conversations;
  if (state.gettingMessage) return;
  dispatch({ type: actions.GETTING_MESSAGE_NOTIFICATIONS });
  
  try {
    const portalMessage = {
      GroupKey: GroupKey,
      ActivityType: ActivityType,
      CustKey: CustomerKey,
      UserKey: UserKey,
      IsInbox: true,
      IsSent: false,
      IsArchive: false
    };

    const response = await data.post(
      `v4/api/accountoverview/portalmessage/for/${GroupKey}?&$top=${1}&$skip=${0}`,
      portalMessage
    );

    dispatch({
      type: actions.GOT_MESSAGE_NOTIFICATIONS,
      messagesRowCount : response.data.count,
    });
  } catch (error) {
    dispatch({
      type: actions.GET_MESSAGE_NOTIFICATIONS_FAILED,
      error: error.message
    });
    throw error;
  }
};
   
export const dispatchToProps = dispatch => ({
  setConversationsToSearchResult: (searchResult, perspectiveId) => {
    dispatch({ type: actions.SET_CONVERSATIONS_TO_SEARCH_RESULT, perspectiveId, conversations: searchResult });
  },
  getConversationStatuses: companyId => {
    dispatch(getConversationStatuses(companyId));
  },
  ensureConversationStatuses: () => {
    dispatch(ensureConversationStatuses());
  },
  getConversationStatus: statusId => {
    return dispatch(getConversationStatus(statusId));
  },
  getLabeledWithCompanyConversations: (companyId, perspective, withCompanyId, label, top, skip) => {
    dispatch(getLabeledWithCompanyConversations(companyId, perspective, withCompanyId, label, top, skip));
  },
  getConversation: conversationId => {
    return dispatch(getConversation(conversationId));
  },
  refreshConversation: () => {
    return dispatch(refreshConversation());
  },
  clearConversations: () => dispatch({ type: actions.CLEAR_CONVERSATIONS }),
  clearConversation: () => {
    dispatch({ type: actions.CLEAR_CONVERSATION });
  },
  setConversationStatusesTo: (conversations, statusId, suppressError) => {
    return dispatch(setConversationStatusesTo(conversations, statusId, suppressError));
  },
  getRelated: addressId => {
    return dispatch(getRelated(addressId));
  },
  fetchRelated: addressId => {
    return dispatch(fetchRelated(addressId));
  },
  setConversationSort: (sortBy, sortDirection) => {
    return dispatch({
      type: actions.SET_CONVERSATION_SORT,
      sortBy,
      sortDirection
    });
  },
  setDocumentSort: (sortBy, sortDirection) => {
    return dispatch({
      type: actions.SET_DOCUMENT_SORT,
      sortBy,
      sortDirection
    });
  },
  getConversationDocuments: (companyId, perspective, withCompanyId, next, top, skip) => {
    return dispatch(getConversationDocuments(companyId, perspective, withCompanyId, next, top, skip));
  },
  updateConversationReadStatuses: (conversationIds, isRead) => {
    return dispatch(updateConversationReadStatuses(conversationIds, isRead));
  },
  getMessages: (GroupKey, ActivityType, CustomerKey, UserKey, IsInbox, IsSent, IsArchive, top ,skip) => {
    return dispatch(getMessages(GroupKey, ActivityType, CustomerKey, UserKey, IsInbox, IsSent, IsArchive,top,skip));
  },
  getMessagesNotification:(GroupKey,ActivityType,CustomerKey,UserKey) => {
    return dispatch(getMessagesNotification(GroupKey,ActivityType,CustomerKey,UserKey));
  }
});
