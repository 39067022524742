import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import Resources from "../lib/resources";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_DISPUTE_CODE: "GETTING_DISPUTE_CODE",
    GOT_DISPUTE_CODE: "GOT_DISPUTE_CODE",
    FAILED_GET_DISPUTE_CODE: "FAILED_GET_DISPUTE_CODE"
  }
};

const fetchDisputeCodes = companyId => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_DISPUTE_CODE });
  return data
    .get(`/v2/api/disputeCode/${companyId}`)
    .then(response => {
      dispatch({ type: actions.GOT_DISPUTE_CODE, disputeCodes: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetDisputeCodeFailure(), "dispute code");
      dispatch({ type: actions.FAILED_GET_DISPUTE_CODE });
      throw error;
    });
};

export const dispatchToProps = dispatch => ({
  fetchDisputeCodes: companyId => {
    return dispatch(fetchDisputeCodes(companyId));
  }
});
