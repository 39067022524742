import data from "../lib/data";
import { handlePromiseError } from "./error-actions";
import Resources from "../lib/resources";
import { compareDates, isEmpty, capStringAtLength } from "../lib/utils";

import { actions as GeneralActions } from "./general-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_ACCOUNTS: "FETCHING_ACCOUNTS",
    FETCHED_ACCOUNTS: "FETCHED_ACCOUNTS",
    FETCH_ACCOUNTS_FAILED: "FETCH_ACCOUNTS_FAILED",

    FETCHING_SUBSCRIPTION: "FETCHING_SUBSCRIPTION",
    FETCHED_SUBSCRIPTION: "FETCHED_SUBSCRIPTION",
    FETCH_SUBSCRIPTION_FAILED: "FETCH_SUBSCRIPTION_FAILED",

    FETCH_DROPDOWN_CONTENT: "FETCH_DROPDOWN_CONTENT",
    UPDATE_DROPDOWN_SORT: "UPDATE_DROPDOWN_SORT"
  }
};

const fetchSubscription = () => (dispatch, getState) => {
  const store = getState();
  const accountsStore = store.accounts;

  const account = accountsStore.selectedAccount;
  const accountId = (account || {}).accountId;
  if (accountsStore.fetchingSubscription || isEmpty(accountId)) {
    return;
  }
  dispatch({ type: actions.FETCHING_SUBSCRIPTION });
  data
    .get(`v1/api/account/${accountId}/subscription`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_SUBSCRIPTION,
        subscription: response.data
      });
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_SUBSCRIPTION_FAILED });
      handlePromiseError(error, Resources.SubscriptionFetchError, "subscription");
    });
};

// Fetches given company and falls back on oldest company
const getDefaultCompany = defaultCompanyId => (dispatch, getState) => {
  let accounts = getState().accounts.accounts;
  let defaultCompany;
  let companies = [];
  accounts.forEach(account => {
    account.companies.forEach(company => {
      if (company.companyId === defaultCompanyId) {
        defaultCompany = company;
      }
      companies.push(company);
    });
  });
  if (defaultCompany) return defaultCompany;
  companies.sort((a, b) => {
    return compareDates(a.createdDate, b.createdDate);
  });
  return companies[0] || {};
};

const fetchDropdownContent = () => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.fetchingAccounts) {
    return;
  }

  dispatch({ type: actions.FETCHING_ACCOUNTS });
  data.get('v2/api/account/dropdown').then(response => {

    let dropdownContent = {};
    if (response.data?.account?.features?.optimizedPerformance) {
      dropdownContent = createDropdownDataWithNewApi(response.data?.account?.companies);
      dispatch({ type: actions.FETCHED_SUBSCRIPTION, subscription: response.data?.account?.subscriptionData });
    } else {
      dropdownContent = createDropdownData(response.data?.account?.companies, Object.assign({}, ...response.data?.keyToAggregatedStatements?.map(item => ({ [item['keyToAggregatedStatements_head']]: item }))));
    }
    let primaryCompany = {};

    // In order to be able to accurately switch between a child company and viewing all accounts, we need to store the primary company. If the user
    // decides to view all accounts, we will need to set various state values that makes the currently selected company this primary company.
    if (response.data.account.companies.length > 0) {
      primaryCompany = response.data.account.companies[0];
    }
    dispatch({ type: actions.FETCHED_ACCOUNTS, accounts: [response.data.account] });
    dispatch({ type: actions.FETCH_DROPDOWN_CONTENT, data: { ...dropdownContent }, primaryCompany })
  })
  .catch(error => {
    dispatch({ type: actions.FETCH_ACCOUNTS_FAILED });
    handlePromiseError(error, Resources.AccountFetchFailure, "accounts");
  });
}

// Iterate through companies and summaries (dictionary) matching values based on companyId,
// "___--" prefix is cross-currency-total
// "USD--" prefix for USD openInvoices
const createDropdownData = (companies, summaries) => {
  let combinedDropdownData = [];
  let longestCustomerName = 0;
  companies.map(comp => {
    if (longestCustomerName < comp.companyName.length && comp.companyName.length <= 69) {
      longestCustomerName = comp.companyName.length;
    }
    const summaryID = '___--'.concat(comp.companyId);
    combinedDropdownData.push(setDropdownRowValues({
      value: comp.companyId,
      companyName: comp.companyName,
      openInvoiceCount: summaries[summaryID] ? summaries[summaryID]?.count : 0,
      customerId: comp.customerId
    }));
  });

  const allAccountObj = {
    openInvoices: summaries['___--']?.count || 0,
    companyName: 'All Accounts',
    value: "ALL_ACCOUNTS_ID",
    displayName: 'All Accounts'
  }

  return { combinedDropdownData, longestCustomerName, allAccountObj };
}

const createDropdownDataWithNewApi = (companies) => {
  let combinedDropdownData = [];
  let longestCustomerName = 0;
  let allAccountObj = {
    openInvoices: 0,
    companyName: 'All Accounts',
    value: "ALL_ACCOUNTS_ID",
    displayName: 'All Accounts'
  };
  let count = 0;
  companies.map(comp => {
    if (longestCustomerName < comp.companyName.length && comp.companyName.length <= 69) {
      longestCustomerName = comp.companyName.length;
    }
    const obj = setDropdownRowValues({
      value: comp.companyId,
      companyName: comp.companyName,
      openInvoiceCount: comp.count,
      customerId:comp.customerId
    })
    count += obj.openInvoices;
    combinedDropdownData.push(obj);

    if (!isEmpty(comp.parentCompanyData)) {
      allAccountObj.openInvoices = comp?.parentCompanyData?.count || 0;
    }
  });
  // Added code for total invoice count in All Account dropdown
  allAccountObj.openInvoices = count|| 0;
  return { combinedDropdownData, longestCustomerName, allAccountObj };
}

const setDropdownRowValues = (company) => {
  return {
    value: company.value,
    displayName: capStringAtLength(company.companyName, Resources.TruncateLimit),
    fullName: company.companyName,
    openInvoices: company.openInvoiceCount,
    customerId: company.customerId? company?.customerId+'': ''
  }
}

const updateDropdownSort = (updatedArray) => dispatch => {
  dispatch({ type: actions.UPDATE_DROPDOWN_SORT, data: updatedArray })
}

export const dispatchToProps = dispatch => ({
  fetchDropdownContent: () => {
    return dispatch(fetchDropdownContent());
  },
  updateDropdownSort: (updatedArray) => {
    return dispatch(updateDropdownSort(updatedArray));
  },
  fetchSubscription: () => {
    dispatch(fetchSubscription());
  },
  getDefaultCompany: defaultCompanyId => {
    return dispatch(getDefaultCompany(defaultCompanyId));
  }
});
